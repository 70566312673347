import React from 'react';
import Navigation from '../Navigation';
import { connect } from 'react-redux';
import { getUsers, addUser, updateUser } from '../../actions';
import LoaderView from '../loader/Loader';
import UserRow from './UserRow';
import { withTranslation } from 'react-i18next';

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      userName: '',
      login: '',
      phone: '',
      role: '3',
      userId: '',
      userActive: '1',
      userRole: '1',
      isLoading: true,
      canEdit: false,
      errorMessage: {
        email: '',
        userName: '',
        phone: '',
      },
      randomUserIndex: Math.floor(Math.random() * 100) + 1,
      isSubmittingValidForm: false,
    };

    this.handleUserFormSubmit = this.handleUserFormSubmit.bind(this);
    this.validateFieldOnBlur = this.validateFieldOnBlur.bind(this);
    this.handleFieldValidation = this.handleFieldValidation.bind(this);
  }

  async componentDidMount() {
    if (!localStorage.token) return (window.location.pathname = '/');
    await this.props
      .getUsers(localStorage.token)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .then(() => {
        this.setState({
          canEdit: this.props.flagStatement.flags.Users.edit,
        });
      });
  };

  componentDidUpdate(prevState) {
    const { isSubmittingValidForm, errorMessage } = this.state;
    const allFormErrors = Object.values(errorMessage).join("");

    if (!prevState.isSubmittingValidForm && isSubmittingValidForm && !allFormErrors.length) {
      this.addUser();
    } else if (prevState.isSubmittingValidForm && allFormErrors.length) {
      this.setState({
        isSubmittingValidForm: false,
      });
    }
  };

  handleUserData = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  editUser = async (data, callback) => {

    await this.props.updateUser(data, localStorage.token)
      .then(() => {
        callback();
      }
    );

    await this.props.getUsers(localStorage.token);
  };

  renderList() {
    const { t } = this.props;
    let rows = [];
    if (
      this.props.users.users !== null &&
      this.props.users.users !== undefined
    ) {
      rows = Object.values(this.props.users.users);
    }
    if (rows.length > 0) {
      return rows.map((user, index) => {
        return <UserRow user={user} save={this.editUser} canEdit={this.state.canEdit}/>
      });
    }
  }

  handleNewUserData = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFieldValidation = (fieldsArray, isTryingToSubmit = false) => {
    const fieldErrorMessages = {};
    const { t } = this.props;

    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const userNameRegex = /^[a-z ,.'-ąćęłńóśźżĄĘŁŃÓŚŹŻ]+$/i;
    const phoneNumberRegex = /^$|^\d{9}$/;

    const validateField = (fieldValue, currentRegex) => currentRegex.test(fieldValue);

    const setFieldValidationResult = (fieldName, errorMessage) => {
      Object.assign(fieldErrorMessages, {
        [fieldName]: errorMessage
      });
    };

    fieldsArray.forEach(({ name, value }) => {
      let errorMessage = '';

      switch (name) {
        case 'email':
          errorMessage = validateField(value, emailRegex) ? '' : t("Please enter a valid email address");
          break;
        case 'userName':
          errorMessage = validateField(value, userNameRegex) ? '' : t("Please enter a valid first and last name");
          break;
        case 'phone':
          errorMessage = validateField(value, phoneNumberRegex) ? '' : t("Please enter a valid phone number");
          break;
        default:
          errorMessage = '';
      }

      setFieldValidationResult(name, errorMessage);
    });

    this.setState({
      errorMessage: {
        ...this.state.errorMessage,
        ...fieldErrorMessages,
      },
      isSubmittingValidForm: isTryingToSubmit,
    });
  };

  validateFieldOnBlur = e => {
    const { name, value } = e.target;
    this.handleFieldValidation([
      {
        name,
        value
      }
    ]);
  };

  addUser = async () => {
    this.setState({
      isLoading: true,
      isSubmittingValidForm: false,
    });

    const {
      email,
      login,
      userName,
      role,
      phone
    } = this.state;

    let data = {
      email,
      login,
      name: userName,
      role: +role,
      phone,
    };

    await this.props.addUser(data, localStorage.token);
    await this.props.getUsers(localStorage.token).then(() => {
      this.setState({
        email: '',
        login: '',
        userName: '',
        role: '3',
        phone: '',
        isLoading: false,
      });
    });
  };

  handleUserFormSubmit = () => {
    const {
      email: emailValue,
      userName: userNameValue,
      phone: phoneNumberValue,
    } = this.state;

    const formFields = [
      {
        name: 'email',
        value: emailValue,
      },
      {
        name: 'userName',
        value: userNameValue,
      },
      {
        name: 'phone',
        value: phoneNumberValue,
      }
    ];

    this.handleFieldValidation(formFields, true);
  };

  render() {
    const { t } = this.props;

    const {
      email: errorMessageEmail,
      userName: errorMessageUserName,
      phone: errorMessagePhone
    } = this.state.errorMessage;

    if (this.state.isLoading) {
      return <LoaderView />;
    }

    return (
      <>
        <Navigation />
        <div id='page-wrapper'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='page-header'>
                <h1>{t("User list")}</h1>
                <div className='h1bb'></div>
              </div>

              <div className="input-set-container">
                <p className="header">
                  {t("New user")}
                </p>
                <div className="inputs">
                  <div className='input-container'>
                    <div className="input-group">
                      <div className="input-label">
                        <i className='fa fa-envelope input-icon'></i>
                      </div>
                      <input
                        type='email'
                        id='Email'
                        name='email'
                        placeholder={t("E-mail")}
                        className={`form-control ${errorMessageEmail.length && 'field-error'}`}
                        required
                        value={this.state.email}
                        onChange={this.handleNewUserData}
                        onBlur={e => this.validateFieldOnBlur(e)}
                      />
                    </div>
                    <p className="input-error-message">
                      {errorMessageEmail}
                    </p>
                  </div>
                  <div className='input-container'>
                    <div className="input-group">
                      <div className="input-label">
                        <i className='fa fa-user input-icon'></i>
                      </div>
                      <input
                        type='text'
                        id='Name'
                        name='userName'
                        placeholder={t("First name and last name")}
                        className={`form-control ${errorMessageUserName.length && 'field-error'}`}
                        required
                        value={this.state.userName}
                        onChange={this.handleNewUserData}
                        onBlur={e => this.validateFieldOnBlur(e)}
                      />
                    </div>
                    <p className="input-error-message">
                      {errorMessageUserName}
                    </p>
                  </div>
                  <div className='input-container'>
                    <div className="input-group">
                      <div className="input-label">
                        <i className='fa fa-phone input-icon'></i>
                      </div>
                      <input
                        type='text'
                        id='Name'
                        name='phone'
                        placeholder={t("Phone number")}
                        className={`form-control ${errorMessagePhone.length && 'field-error'}`}
                        maxLength='9'
                        value={this.state.phone}
                        onChange={this.handleNewUserData}
                        onBlur={e => this.validateFieldOnBlur(e)}
                      />
                    </div>
                    <p className="input-error-message">
                      {errorMessagePhone}
                    </p>
                  </div>
                    <div className="input-group">
                        <div className="input-label">
                            rola
                        </div>
                        <select
                            name='role'
                            className='form-control'
                            id='Role'
                            value={this.state.role}
                            onChange={this.handleNewUserData}
                          >
                            <option value='1'>{t("Administrator")}</option>
                            <option value='2'>{t("Programmer")}</option>
                            <option value='3'>{t("User")}</option>
                            <option value='4'>{t("Guest")}</option>
                          </select>
                    </div>
                </div>
                <div className="buttons">
                  <button
                    type='button'
                    className='btn btn-success'
                    onClick={this.handleUserFormSubmit}
                  >
                    {t("Add")} <i className='fa fa-check'></i>
                  </button>
                </div>
            </div>

              <table
                className='table table-bordered table-td-middle'
                id='users'
              >
                <thead>
                  <tr>
                    <th>{t("E-mail")}</th>
                    <th>{t("Login")}</th>
                    <th>{t("First name and last name")}</th>
                    <th>{t("Phone number")}</th>
                    <th>{t("Role")}</th>
                    <th width='175'>{t("Status")}</th>
                    <th>{t("Created")}</th>
                    {this.state.canEdit && <th>{t("Edit")}</th>}
                  </tr>
                </thead>
                <tbody>{this.renderList()}</tbody>
              </table>
            </div>
        </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state, users: state.users };
};

export default connect(mapStateToProps, {
  getUsers,
  addUser,
  updateUser
}) (withTranslation()(Users));
