import React from 'react';

import {
    SButtonIconLeft,
    SButtonIconRight,
} from './styles';

const IconContent = ({
    children,
    iconType,
    Icon,
}) => (
    <>
        {
            iconType === 'left' && (
                <SButtonIconLeft>
                    <Icon />
                </SButtonIconLeft>
            )
        }
        { children }
        {
            iconType === 'right' && (
                <SButtonIconRight>
                    <Icon />
                </SButtonIconRight>
            )
        }
    </>
);

export default IconContent;
