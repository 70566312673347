import React from "react";
import {useTranslation} from "react-i18next";

const P3GTransactionStatus = ({status, baseAmount, currentAmount}) => {
    const {t} = useTranslation();
    const handleStatus = () => {
        if (currentAmount === 0) {
            return t("Refund - full");
        } else if (currentAmount > 0 && currentAmount < baseAmount) {
            return t("Refund - partial");
        } else if (status === 2) {
            return t("Pending");
        } else if (status === 4) {
            return t("Completed");
        } else {
            return '-';
        }
    };

    const handleStatusClassName = () => {
        if (
            currentAmount === 0 ||
            (currentAmount > 0 && currentAmount < baseAmount) ||
            status === 2
        ) {
            return 'text-warning';
        } else if (status === 1) {
            return 'text-info';
        } else if (status === 4) {
            return 'text-success';
        }
    };

    return (
        <span className={handleStatusClassName()}>{handleStatus()}</span>
    );
}

export default P3GTransactionStatus;