import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useTableColumnsTranslation = columns => {
    const {t} = useTranslation();

    return useMemo(
        () => columns.map(column => ({
                ...column,
                headerName: t(column.headerName),
                description: t(column.description)
            })
        ), [t, columns]);
};