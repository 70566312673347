import React from 'react';
import {Tooltip, Typography} from '@mui/material';
import * as S from './styles'

const IconButtonWithTooltip = ({tooltipText, onClick, Icon, disabled}) => (
    <Tooltip title={<Typography fontSize={12}>{tooltipText}</Typography>}>
        <span>
            <S.ButtonWithIcon
                onClick={onClick}
                disabled={disabled}
            >
                <Icon/>
            </S.ButtonWithIcon>
        </span>
    </Tooltip>
);

export default IconButtonWithTooltip;