import React from "react";
import {IconButton, InputAdornment, MenuItem} from "@mui/material";
import {t} from "i18next";
import Text from "../../../atoms/Typography/Text";
import getParsedZoneDate from "../../../../services/parserDate";
import Button from "../../../atoms/Button";
import DataGridHeaderWithInput from "../../../molecules/DataGridHeaderWithInput/DataGridHeaderWithInput";
import MoneyWithCurrency from "../../../molecules/MoneyWithCurrency/MoneyWithCurrency";
import {TextField} from "../../../atoms/TextField/TextField";
import DateRangeInput from "../../../molecules/DateRangeInput/DataRangeInput";
import {Select} from "../../../atoms/Select/Select";
import {AiOutlineClose, FaList} from "react-icons/all";
import IconButtonWithTooltip from "../../../molecules/IconButtonWithTooltip/IconButtonWithTooltip";
import P3GTransactionStatus from "../../../molecules/P3GTransactionStatus/P3GTransactionStatus";
import moment from "moment/moment";

export const getColumns = (methods, merchantGroup, openDetailsModal, initialDate) => {
    const {control, reset, getValues, formState:{errors}} = methods;

    return [
        {
            headerName: 'Order ID',
            field: 'orderReference',
            flex: 1,
            minWidth: 320,
            renderHeader: () => (
                <DataGridHeaderWithInput
                    label={t('Order ID')}
                    name={"orderReference"}
                    control={control}
                    Input={TextField}
                    InputProps={{
                        error: !!errors.orderReference,
                        helperText: errors.orderReference?.message,
                        label: t('Order ID'),
                        InputProps: {
                            endAdornment: (
                                !!getValues("orderReference") && <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            reset(formValues => ({
                                                ...formValues,
                                                orderReference: "",
                                            }))
                                        }}
                                        edge="end"
                                    >
                                        {<AiOutlineClose />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }
                    }}
                />
            ),
            renderCell: ({row}) => {
                return (
                    <Text>{row.order.reference}</Text>
                );
            },
        },
        {
            headerName: 'Payment ID',
            field: 'canonicalId',
            flex: 1,
            minWidth: 200,
            renderHeader: () => (
                <DataGridHeaderWithInput
                    label={t('Payment ID')}
                    name={"canonicalId"}
                    control={control}
                    Input={TextField}
                    InputProps={{
                        error: !!errors.canonicalId,
                        helperText: errors.canonicalId?.message,
                        label: t('Payment ID'),
                        InputProps: {
                            endAdornment: (
                                !!getValues("canonicalId") && <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            reset(formValues => ({
                                                ...formValues,
                                                canonicalId: "",
                                            }))
                                        }}
                                        edge="end"
                                    >
                                        {<AiOutlineClose />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }
                    }}
                />
            ),
            renderCell: ({row}) => {
                return (
                    <Text>{row?.currentPayment?.canonicalId || "-"}</Text>
                );
            },
        },
        {
            headerName: 'Supplier ID',
            field: 'supplier_id',
            flex: 1,
            minWidth: 200,
            renderHeader: () => (
                <DataGridHeaderWithInput
                    label={t('Supplier ID')}
                    name={"providerCanonicalId"}
                    control={control}
                    Input={TextField}
                    InputProps={{
                        error: !!errors.providerCanonicalId,
                        helperText: errors.providerCanonicalId?.message,
                        label: t('Supplier ID'),
                        InputProps: {
                            endAdornment: (
                                !!getValues("providerCanonicalId") && <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => {
                                            reset(formValues => ({
                                                ...formValues,
                                                providerCanonicalId: "",
                                            }))
                                        }}
                                        edge="end"
                                    >
                                        {<AiOutlineClose />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }
                    }}
                />
            ),
            renderCell: ({row}) => {
                return (
                    <Text>{row?.currentPayment?.providerCanonicalId || "-"}</Text>
                );
            },
        },
        {
            headerName: 'Date',
            field: 'date',
            flex: 1,
            minWidth: 260,
            renderHeader: () => (
                <DataGridHeaderWithInput
                    label={t('Date')}
                    name={"date"}
                    control={control}
                    Input={DateRangeInput}
                    InputProps={{
                        initialStartDate: moment(initialDate.startDate, 'YYYY-MM-DD'),
                    }}
                />
            ),
            renderCell: ({row}) => {
                return (
                    <Text>{getParsedZoneDate(row.createdAt.date)}</Text>
                );
            },
        },
        {
            headerName: 'Merchant',
            field: 'merchant',
            flex: 1,
            minWidth: 200,
            // renderHeader: () => (
            //     <DataGridHeaderWithInput
            //         label={t('Merchant')}
            //         name={"merchantId"}
            //         control={control}
            //         Input={Select}
            //         InputChildren={merchantGroup?.map((merchant) => <MenuItem key={merchant.MerchantId} value={merchant.MerchantId}>{merchant.CompanyName}</MenuItem>)}
            //     />
            // ),
            renderCell: ({row}) => {
                return (
                    <Text>{row?.shop?.name || "-"}</Text>
                );
            },
        },
        {
            headerName: 'Base/Initial amount',
            field: 'base_amount',
            flex: 1,
            minWidth: 130,
            renderCell: ({row}) => {
                return (
                    <Text><MoneyWithCurrency value={row.order.baseAmount}/></Text>
                );
            },
        },
        {
            headerName: 'Current amount',
            field: 'current_amount',
            flex: 1,
            minWidth: 130,
            renderCell: ({row}) => {
                return (
                    <Text><MoneyWithCurrency value={row.order.amount} /></Text>
                );
            },
        },
        {
            headerName: 'Status',
            field: 'status',
            flex: 1,
            minWidth: 300,
            renderCell: ({row}) => {
                return (
                    <Text bold>
                        <P3GTransactionStatus
                            status={row?.status}
                            currentAmount={row.order.amount}
                            baseAmount={row.order.baseAmount}
                        />
                    </Text>
                );
            },
        },
        {
            headerName: 'Payment method',
            field: 'payment_method',
            flex: 1,
            minWidth: 160,
            renderCell: ({row}) => {
                return (
                    <Text>{row?.currentPayment?.method.name || "-"}</Text>
                );
            },
        },
        {
            headerName: 'Settlement',
            field: 'settlement',
            flex: 1,
            minWidth: 140,
            renderCell: ({row}) => {
                return (
                    <Text>{row?.settlement?.reference || "-"}</Text>
                );
            },
        },
        {
            field: 'actions',
            flex: 1,
            minWidth: 140,
            renderHeader: () => (
                <Button
                    className={"mt-3"}
                    type={"submit"}
                    size={'s'}
                >
                    {t("Filter")}
                </Button>
            ),
            renderCell: ({row}) => {
                return (
                    <>
                        <IconButtonWithTooltip
                            tooltipText={t("Details")}
                            onClick={openDetailsModal(row)}
                            Icon={FaList}
                            disabled={row?.status !== 4}
                        />
                    </>
                );
            }
        }
    ]
};