import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import BasicLink from '../../atoms/BasicLink';

import Notification from '../../molecules/Notification';
import ModalTemplate from '../../templates/ModalTemplate';

const CookieModal = () => {
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);

    const notificationMaxHeight = "50vh";
    const notificationSize = "l";

    useEffect(() => {
        const wasCookieInfoClosed = cookie.load("pp_cookie_information");

        !!wasCookieInfoClosed || setIsNotificationVisible(true);
    }, []);

    const handleClose = () => {
        const currentDate = new Date();

        cookie.save(
            "pp_cookie_information",
            true,
            {
                secure: true,
                expires: new Date(currentDate.setMonth(currentDate.getMonth() + 1)),
            },
        );

        setIsNotificationVisible(false);
    };

    const COOKIE_FOOTER_CONFIG = {
        buttonsData: [
            {
                name: 'Respingere cookie-uri non tehnice',
                onClick: () => handleClose(),
                type: "white",
                size: "s/m",
            },
            {
                name: 'Confirmare',
                onClick: () => handleClose(),
                size: "s/m",
            },
        ],
        buttonsAlign: "flex-end",
        reverseOnMobile: true,
    };

    return (
        <Notification
            size={ notificationSize }
            position="bottom"
            maxHeight={ notificationMaxHeight }
            isVisible={ isNotificationVisible }
        >
            <ModalTemplate
                title="Acest site folosește cookie-uri"
                titleAlign="left"
                maxHeight={ notificationMaxHeight }
                size={ notificationSize }
                handleClose={ handleClose }
                footerConfig={ COOKIE_FOOTER_CONFIG }
                withoutCloseButton
            >
                PayPo Plăți Amânate IFN S.A împreună cu partenerii noștri, folosim cookie-uri pentru a asigura funcționarea corectă a site-ului, pentru a adapta conținutul la preferințele dumneavoastră și pentru a urmări statisticile. Puteți fi de acord cu toate cookie-urile sau puteți utiliza site-ul nostru web numai cu cele care sunt necesare pentru funcționarea corectă a site-ului. Informații detaliate pot fi găsite în
                
                { " " }
                
                <BasicLink
                    href="https://static.paypo.ro/documents/Politica_de_confidentialitate_si_cookies.pdf"
                    target="_blank"
                >
                    Politica de confidențialitate și cookies
                </BasicLink>.
            </ModalTemplate>
        </Notification>
    );
};
 
export default CookieModal;