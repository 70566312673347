import React from "react";
import Wrapper from "../../atoms/Wrapper/Wrapper";
import {DataGrid} from '@mui/x-data-grid';
import * as S from './styles';
import {Chip} from "@mui/material";
import {colors} from "../../../styles/variables";
import {useTranslation} from "react-i18next";
import Pagination from "../../organisms/Pagination/Pagination";

const TableWrapper = ({
    tableData,
    tableColumns,
    isLoading,
    totalCount,
    rowsPerPage,
    pages,
    currentPage,
    handleChangePage,
    headerHeight = 56,
    height,
    ...props
}) => {
    const {t} = useTranslation();
    const handleItemsFrom = () => (currentPage === 1 ? 1 : (currentPage - 1) * rowsPerPage + 1)
    const handleItemsTo = () => ((currentPage * rowsPerPage) < totalCount ? currentPage * rowsPerPage : totalCount)
    const handleTotalCountLabel = () => handleItemsFrom() + ' - ' + handleItemsTo() + ' ' + t('of') + ' ' + totalCount

    return (
        <Wrapper>
            <S.TableWrapper>
                <DataGrid
                    rows={!!tableData ? tableData : []}
                    columns={tableColumns}
                    loading={isLoading}
                    autoGenerateColumns={false}
                    disableSelectionOnClick
                    hideFooter
                    headerHeight={headerHeight}
                    sx={{height: !!height ? height : 'calc(100vh - 300px)', minHeight: '300px'}}
                    {...props}
                />
            </S.TableWrapper>
            <Wrapper
                directionRow
                justifyContent={!!totalCount ? 'space-between' : 'flex-end'}
                alignItems='center'
                className={'mt-4'}
            >
                {
                    !!totalCount && (
                        <Wrapper>
                            <Chip
                                size={"small"}
                                label={handleTotalCountLabel()}
                                sx={{
                                    backgroundColor: colors.silverChalice,
                                    color: "white",
                                    fontSize: "1.2rem",
                                    fontWeight: "bold"
                                }}
                            />
                        </Wrapper>
                    )
                }
                {
                    !!handleChangePage && (
                        <Wrapper>
                            <Pagination
                                dataLength={tableData?.length}
                                rowsPerPage={rowsPerPage}
                                currentPage={currentPage}
                                onPageChange={handleChangePage}
                            />
                        </Wrapper>
                    )
                }
            </Wrapper>
        </Wrapper>
    );
}

export default TableWrapper;