import React, {useState} from "react";
import 'react-dates/initialize';
import {DateRangePicker} from "react-dates";
import {DateRangePickerWrapper} from "../../atoms/DateRangePicker/DateRangePicker";

const DateRangeInput = ({onChange, initialStartDate}) => {
    const [date, setDate] = useState({
        startDate: initialStartDate || null,
        endDate: null,
    })
    const [focusedInput, setFocusedInput] = useState(null);
    const onFocusChange = (focusedInput) => {
        setFocusedInput(focusedInput);
    }

    return (
        <DateRangePickerWrapper>
            <DateRangePicker
                startDate={date.startDate}
                endDate={date.endDate}
                startDateId="startDateId"
                endDateId="endDateId"
                onDatesChange={(date) => {
                    setDate(date);
                    onChange(date);
                }}
                focusedInput={focusedInput}
                onFocusChange={onFocusChange}
                displayFormat="YYYY-MM-DD"
                verticalSpacing={0}
                isOutsideRange={() => false}
                hideKeyboardShortcutsPanel
                showClearDates
            />
        </DateRangePickerWrapper>
    );
}

export default DateRangeInput;