import React from "react";
import {useMutation} from "react-query";
import {postTransactionReturn} from "../services/transactionService";
import {toast} from "react-toastify";
import {t} from "i18next";

export const useTransactionRefund = (onSuccess) => (
    useMutation({
        mutationFn: (payload) => postTransactionReturn(payload),
        onSuccess: () => {
            onSuccess();
        },
        onError: () => {
            toast.error(t('There was an error while refunding.'));
        },
    })
);