import React from 'react';
import Navigation from '../Navigation';
import { connect } from 'react-redux';
import { getOnlineTransactions, getExportP3GTransactions } from '../../actions';
import moment from 'moment';
import LoaderView from '../loader/Loader';
import DatePicker from 'react-datepicker';
import P3GModal from './P3GModal'
import { withTranslation } from 'react-i18next';

class TransactionList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateFrom: moment(new Date()).subtract(1, 'months').toDate(),
            dateTo: new Date(),
            orderStatus: '0',
            page: 1,
            lastPage: 1,
            showExportRaport: false,
            isLoading: true,
            showAlert: false,
            showCompanyName: false,
            hasModalClosed: false,
            paymentMethod: '',
            merchantGroup: [],
            reference: '',
            merchantId: null
        };
    }

    getFiltersDataFromState = customPage => {
        const {
            page,
            orderStatus,
            dateFrom,
            dateTo,
            paymentMethod,
            merchantId,
            reference,
        } = this.state;

        return {
            page: customPage || page,
            status: parseInt(orderStatus),
            dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(dateTo).format('YYYY-MM-DD'),
            merchantId: parseInt(merchantId),
            paymentMethod,
            reference,
        };
    };

    async componentDidMount() {
        if (!localStorage.token) return (window.location.pathname = '/');

        const filtersData = this.getFiltersDataFromState();

        await this.props
            .getOnlineTransactions(filtersData, localStorage.token)
            .then(() => {
                if (
                    Object.keys(this.props.onlineTransactions.transactions)
                        .length === 0
                ) {
                    this.setState({ showAlert: true });
                }
            })
            .then(() => {
                if (this.props.onlineTransactions.pagination) {
                    this.setState({
                        page: this.props.onlineTransactions.pagination
                            .currentPage,
                        lastPage: this.props.onlineTransactions.pagination
                            .lastPage,
                    });
                }
            })
            .then(() => {
                const { merchantGroup } = this.props.onlineTransactions;

                if (merchantGroup && merchantGroup.length > 1) {
                    this.setState({
                        showCompanyName: true,
                        merchantGroup: merchantGroup
                    });
                }
            })
            .then(() => {
                this.setState({
                    isLoading: false,
                });
            });
    }

    countingTransaction(transaction) {
        let amount = transaction / 100;
        const correctAmount = amount.toFixed(2).replace('.', ',');
        return `${correctAmount} ${process.env.REACT_APP_CURRENCY}`;
    }

    handleStatus = (status, baseAmount, currentAmount) => {
        const { t } = this.props;

        if (currentAmount === 0) {
            return t("Refund - full");
        } else if (currentAmount > 0 && currentAmount < baseAmount) {
            return t("Refund - partial");
        } else if (status === 2) {
            return t("Pending");
        } else if (status === 4) {
            return t("Completed");
        } else {
            return '-';
        }
    };

    handleStatusClassName = (status, baseAmount, currentAmount) => {
        if (
            currentAmount === 0 ||
            (currentAmount > 0 && currentAmount < baseAmount) ||
            status === 2
        ) {
            return 'text-warning';
        } else if (status === 1) {
            return 'text-info';
        } else if (status === 4) {
            return 'text-success';
        }
    };

    handleModal = () => {
        this.setState({ hasModalClosed: true });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.hasModalClosed !== this.state.hasModalClosed) {
            this.setState({
                isLoading: true,
            });

            const filtersData = this.getFiltersDataFromState();

            this.props.getOnlineTransactions(filtersData, localStorage.token).then(() => {
                this.setState({ isLoading: false, hasModalClosed: false });
            });
        }
    }

    handleSettlement = (settlement) => {
        return settlement || '-'
    }

    renderTableRow() {
        let rows = [];

        const { showCompanyName } = this.state;

        const { transactions } = this.props.onlineTransactions;

        if (transactions != null) {
            rows = Object.values(transactions);
        }

        if (rows.length > 0) {
            return rows.map((transaction, index) => {
                return (
                    <tr className="orderRow" key={index}>
                        <td>{transaction.payment.canonicalId}</td>
                        <td>{transaction.payment.providerCanonicalId}</td>
                        <td>
                            {moment(transaction.createdAt.date).format(
                                'YYYY-MM-DD HH:mm:ss'
                            )}
                        </td>
                        {showCompanyName && (
                            <td>{transaction.shop.name}</td>
                        )}
                        <td>{transaction.order.reference}</td>
                        <td>
                            {this.countingTransaction(transaction.order.baseAmount)}
                        </td>
                        <td>
                            {this.countingTransaction(transaction.order.amount)}
                        </td>
                        <td
                            className={this.handleStatusClassName(
                                transaction.status,
                                transaction.order.baseAmount,
                                transaction.order.amount
                            )}>
                            {this.handleStatus(
                                transaction.status,
                                transaction.order.baseAmount,
                                transaction.order.amount
                            )}
                        </td>
                        <td>{transaction.paymentMethod}</td>
                        <td>{this.handleSettlement(transaction.settlement)}</td>
                        {transaction.status === 4 ? <td><P3GModal
                            handleModal={this.handleModal}
                            baseAmount={transaction.order.baseAmount}
                            currentAmount={transaction.order.amount}
                            status={this.handleStatus(
                                transaction.status,
                                transaction.order.baseAmount,
                                transaction.order.amount)}
                            referenceId={transaction.order.reference}
                            paymentId={transaction.payment.canonicalId}
                            transactionId={transaction.id}
                            /></td>
                        : <td></td>}
                    </tr>
                );
            });
        }
    }

    filterBtn = async () => {
        this.setState({ isLoading: true });

        const filtersData = this.getFiltersDataFromState(1);

        await this.props
            .getOnlineTransactions(filtersData, localStorage.token)
            .then(() => {
                if (
                    Object.keys(this.props.onlineTransactions.transactions)
                        .length === 0
                ) {
                    this.setState({ showAlert: true });
                } else {
                    this.setState({ showAlert: false });
                }
            })
            .then(() => {
                this.setState({
                    page: 1,
                    lastPage: this.props.onlineTransactions.pagination
                        ? this.props.onlineTransactions.pagination.lastPage
                        : 1,
                });
            })
            .then(() => {
                this.setState({ isLoading: false });
            });
    };

    resetBtn = async () => {
        this.setState({ isLoading: true });

        const data = {
            page: 1,
            status: '0',
            dateFrom: moment(new Date())
                .subtract(6, 'months')
                .format('YYYY-MM-DD'),
            dateTo: moment(new Date()).format('YYYY-MM-DD'),
            reference: '',
            merchantId: null,
        };

        await this.props
            .getOnlineTransactions(data, localStorage.token)
            .then(() => {
                if (
                    Object.keys(this.props.onlineTransactions.transactions)
                        .length === 0
                ) {
                    this.setState({ showAlert: true });
                } else {
                    this.setState({ showAlert: false });
                }
            })
            .then(() => {

                const { pagination } = this.props.onlineTransactions;

                this.setState({
                    page: 1,
                    orderStatus: '0',
                    dateFrom: moment(new Date()).subtract(6, 'months').toDate(),
                    dateTo: new Date(),
                    isLoading: false,
                    lastPage: pagination ? pagination.lastPage : 1,
                    paymentMethod: null,
                    reference: '',
                    merchantId: null,
                });
            });
    };

    collectDataForSearchButton = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    paginationClick = async () => {
        this.setState({ isLoading: true });
        let currentPage = this.state.page;
        currentPage = currentPage + 1;

        this.setState({
            page: currentPage,
        });

        const filtersData = this.getFiltersDataFromState(currentPage);

        await this.props
            .getOnlineTransactions(filtersData, localStorage.token)
            .then(() => {
                this.setState({ isLoading: false });
            });
    };

    paginationPreviousClick = async () => {
        this.setState({ isLoading: true });
        let currentPage = this.state.page;
        currentPage = currentPage - 1;

        this.setState({
            page: currentPage,
        });

        const filtersData = this.getFiltersDataFromState(currentPage);

        await this.props
            .getOnlineTransactions(filtersData, localStorage.token)
            .then(() => {
                this.setState({ isLoading: false });
            });
    };

    handleExport = () => {
        let data = {
            status: this.state.orderStatus,
            dateFrom: moment(this.state.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(this.state.dateTo).format('YYYY-MM-DD'),
            paymentMethod: this.state.paymentMethod,
            reference: this.state.reference
        };
        this.props.getExportP3GTransactions(data, localStorage.token);
    };

    renderPaymentMethods = () => {
        let rows = [];
        if (
            this.props.onlineTransactions.filters !== null &&
            this.props.onlineTransactions.filters !== undefined
        ) {
            rows = Object.entries(
                this.props.onlineTransactions.filters.paymentMethods
            );
        }

        if (rows.length > 0) {
            return rows.map((paymentMethod, index) => {
                return (
                    <option key={index} value={paymentMethod[0]}>
                        {paymentMethod[1]}
                    </option>
                );
            });
        }
    };

    handleDate(value, type) {
        this.setState({
            [type]: value,
        });
    }

    render() {
        const { t } = this.props;

        if (this.state.isLoading) {
            return <LoaderView />;
        }

        return (
            <>
                <Navigation />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-header">
                                <h1>{t("Money transactions")}</h1>
                            </div>
                            <div className="input-set-container">
                                <p className="header">{t("Filtration")}</p>
                                <div className="inputs">
                                    <div className="input-group">
                                        <div className="input-label">
                                            {t("status")}
                                        </div>
                                        <select
                                            name="orderStatus"
                                            className="form-control"
                                            id="selectFilter"
                                            defaultValue={
                                                this.state.orderStatus
                                            }
                                            onChange={
                                                this.collectDataForSearchButton
                                            }>
                                            <option value="0">{t("All")}</option>
                                            <option value="2">
                                                {t("Pending")}
                                            </option>
                                            <option value="4">{t("Completed")}</option>
                                            <option value="full">
                                                {t("Refund - full")}
                                            </option>
                                            <option value="partial">
                                                {t("Refund - partial")}
                                            </option>
                                        </select>
                                    </div>
                                    <div className="input-group">
                                        <div className="input-label">
                                            {t("payment method")}
                                        </div>
                                        <select
                                            name="paymentMethod"
                                            className="form-control"
                                            id="selectFilter"
                                            defaultValue={
                                                this.state.paymentMethod
                                            }
                                            onChange={
                                                this.collectDataForSearchButton
                                            }>
                                            <option value="">
                                                {t("Choose payment methods")}
                                            </option>
                                            {this.renderPaymentMethods()}
                                        </select>
                                    </div>
                                    {this.state.showCompanyName && (
                                        <div className="input-group">
                                            <div className="input-label">
                                                {t("merchant")}
                                            </div>
                                            <select
                                                name="merchantId"
                                                className="form-control"
                                                id="selectMerchantId"
                                                defaultValue={this.state.merchantId}
                                                onChange={this.collectDataForSearchButton}
                                            >
                                                {
                                                    this.state.merchantGroup.map(({ CompanyName, MerchantId }) => (
                                                        <option value={MerchantId} key={CompanyName}>
                                                            {CompanyName}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    )}
                                    <div className="input-group">
                                        <div className="input-label">{t("from")}</div>
                                        <DatePicker
                                            name="dateFrom"
                                            id="selectDateFrom"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.dateFrom}
                                            onChange={(value) =>
                                                this.handleDate(
                                                    value,
                                                    'dateFrom'
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="input-group">
                                        <div className="input-label">{t("until")}</div>
                                        <DatePicker
                                            name="dateTo"
                                            id="selectDateTo"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.dateTo}
                                            onChange={(value) =>
                                                this.handleDate(value, 'dateTo')
                                            }
                                        />
                                    </div>
                                    <div className="input-group">
                                        <div className="input-label">
                                            {t("ID")}
                                        </div>
                                        <input
                                            type="text"
                                            value={this.state.reference}
                                            id="ledgerId"
                                            className="form-control"
                                            placeholder={t("ID")}
                                            onChange={(e) =>
                                                this.setState({
                                                    reference: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="buttons">
                                    <button
                                        className="btn btn-primary"
                                        id="btnSearch"
                                        type="button"
                                        onClick={this.filterBtn}>
                                        {t("Search")}
                                    </button>
                                    <button
                                        className="btn btn-default"
                                        value="Szukaj"
                                        type="button"
                                        onClick={this.resetBtn}>
                                        {t("Reset")}
                                    </button>
                                </div>
                            </div>
                            <div
                                className={
                                    this.state.showAlert
                                        ? 'row display-none'
                                        : 'row'
                                }></div>

                            <div
                                className={
                                    this.state.showAlert
                                        ? 'alert alert-warning'
                                        : 'alert alert-warning display-none'
                                }
                                role="alert">
                                {t("No data meet the criteria")}
                            </div>

                            <div
                                className={
                                    this.state.showAlert
                                        ? 'ordersList display-none'
                                        : 'ordersList'
                                }>
                                <div className="col-md-12 text-right mt-2 mb-2">
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={this.handleExport}>
                                        {t("Export to CSV file")}
                                        <i className="fa fa-download"></i>
                                    </button>
                                </div>
                                <table
                                    className="table table-striped table-bordered table-sm table-td-middle"
                                    id="orders">
                                    <thead>
                                        <tr>
                                            <th>{t("Payment ID")}</th>
                                            <th>{t("Supplier ID")}</th>
                                            <th>{t("Date")}</th>
                                            {this.state.showCompanyName && (
                                                <th>{t("Merchant")}</th>
                                            )}
                                            <th>{t("Order ID")}</th>
                                            <th>{t("Base/Initial amount")}</th>
                                            <th>{t("Current amount")}</th>
                                            <th>{t("Status")}</th>
                                            <th>{t("Payment method")}</th>
                                            <th>{t("Settlement")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.renderTableRow()}</tbody>
                                </table>
                                <div style={{ textAlign: 'right' }}>
                                    {this.state.page > 1 && (
                                        <button
                                            className="passwordReminder"
                                            onClick={
                                                this.paginationPreviousClick
                                            }>
                                            <i className="fa fa-arrow-left"></i>
                                        </button>
                                    )}

                                    {t("Page {{page}} of {{lastPage}}", {page:this.state.page, lastPage:this.state.lastPage})}

                                    {this.state.page < this.state.lastPage && (
                                        <button
                                            className="passwordReminder"
                                            onClick={this.paginationClick}>
                                            <i className="fa fa-arrow-right"></i>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state, onlineTransactions: state.onlineTransactions };
};

export default connect(mapStateToProps, {
    getOnlineTransactions,
    getExportP3GTransactions,
})(withTranslation()(TransactionList));
