import React from "react";
import Wrapper from "../../../atoms/Wrapper/Wrapper";
import {Controller, useFormContext} from "react-hook-form";
import {TextField} from "../../../atoms/TextField/TextField";
import Button from "../../../atoms/Button";
import {useTranslation} from "react-i18next";

const P3GTransactionRefundForm = ({
    onSubmit,
    isConfirmation,
    setConfirmation,

}) => {
    const {t} = useTranslation();
    const {control, trigger, handleSubmit, formState:{errors}} = useFormContext();
    const handleCancelRefund = () => () => {
        setConfirmation(false);
    }

    const handleConfirmRefund = () => () => {
        const result = handleIsValid();

        result.then((response) => {
            if(response) {
                setConfirmation(true);
            }
        })
    }

    const handleIsValid = async () => {
        return await trigger();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Wrapper
                fullWidth
            >
                {!isConfirmation && <Controller
                    name={"amount"}
                    control={control}
                    render={({field: {onChange, value}}) =>
                        <TextField
                            type={"number"}
                            onChange={onChange}
                            value={value}
                            error={!!errors.amount}
                            helperText={errors.amount?.message}
                            InputProps={{
                                inputProps: {
                                    step: 0.01,
                                }
                            }}
                            sx={{
                                "& input": {
                                    padding: "12px 14px",
                                    fontSize: "1.2rem",
                                    lineHeight: 2,
                                }
                            }}
                        />
                    }
                />}
            </Wrapper>
            <Wrapper
                fullWidth
                directionRow
                justifyContent={"flex-end"}
            >
                {!isConfirmation && <Wrapper>
                    <Button
                        className={"mt-3"}
                        onClick={handleConfirmRefund()}
                        size={'s'}
                    >
                        {t("Order refund")}
                    </Button>
                </Wrapper>}
                {!!isConfirmation && <Wrapper directionRow>
                    <Button
                        className={"mt-3 mr-3"}
                        onClick={handleCancelRefund()}
                        size={'s'}
                    >
                        {t("Cancel refund")}
                    </Button>
                    <Button
                        className={"mt-3"}
                        type={"submit"}
                        size={'s'}
                    >
                        {t("Confirm")}
                    </Button>
                </Wrapper>}
            </Wrapper>
        </form>
    );
}

export default P3GTransactionRefundForm;