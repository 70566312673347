export default (state = {}, action) => {
  switch (action.type) {
    case "GET_ACCOUNTANCY":
      return { ...state, accountancy: action.payload }
    case "GET_EXPORT_ACCOUNTANCY":
        return {...state, exportUrl: action.payload}
    case "GET_EXPORT_BATCH_ACCOUNTANCY": 
        return {...state, exportBatchUrl: action.payload}
    case "GET_ACCOUNTANCY_PAGINATION":
      return {...state, accountancyPagination: action.payload}
    case "GET_MERCHANT_GROUP":
      return {...state, merchantGroup: action.payload}
    default:
      return state;
  }
};
