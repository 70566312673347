import React from 'react';
import Chart from 'react-google-charts';
import Navigation from './Navigation';
import { googleChartData } from '../actions';
import { connect } from 'react-redux';
import moment from 'moment';
import LoaderView from './loader/Loader';
import { withTranslation } from 'react-i18next';

class SalesmanPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataChart: [],
      isLoading: true,
      isChartVisable: false,
    };
  }

  async componentDidMount() {
    const { t } = this.props;
    if (!localStorage.token) return (window.location.pathname = '/');
    let today = new Date();
    today = moment(today).format('YYYY-MM-DD');
    let previousDate = moment(today).subtract(6, 'months').format('YYYY-MM-DD');
    await this.props
      .googleChartData(previousDate, today, localStorage.token)
      .then(() => {
        let transformArrayToObj = Array.from(
          this.props.chartData.googleChartData
        );
        let salesmanChartData = [
          [
            { type: 'string', label: t("Month") },
            { type: 'number', label: t("Average value of loans") },
            { type: 'number', label: t("Number of loans") },
          ],
        ];
        transformArrayToObj.map((obj) => {
          salesmanChartData.push(Object.values(obj));
          this.setState({
            dataChart: salesmanChartData,
          });
        });
      })
      .then(() => {
        this.setState({ isLoading: false });
      })
      .then(() => {
        if (this.state.dataChart.length > 0) {
          this.setState({ isChartVisable: true });
        }
      });
  }

  render() {
    const { t } = this.props;

    if (this.state.isLoading) {
      return <LoaderView />;
    } else {
      return (
        <>
          <Navigation />
          <div id='page-wrapper'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='page-header'>
                  <h1>{t("Merchant panel")}</h1>
                  <div className='h1bb'></div>
                </div>
              </div>
            </div>
            {this.state.isChartVisable ? (
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='card'>
                    <div className='card-header'>{t("Loan Statistics")}</div>
                    <div className='card-body'>
                      <Chart
                        width='100%'
                        height='500px'
                        loader={<LoaderView />}
                        chartType='LineChart'
                        data={this.state.dataChart}
                        options={{
                          pointSize: 8,
                          series: {
                            0: { targetAxisIndex: 0 },
                            1: { targetAxisIndex: 1 }
                          },
                          vAxes: {
                            0: { title: t("Average value of loans") },
                            1: { title: t("Number of loans") }
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className='alert alert-warning' role='alert'>
                {t("No data to display")}
              </div>
            )}
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return { ...state, chartData: state.googleChartData };
};

export default connect(mapStateToProps, { googleChartData })(withTranslation()(SalesmanPanel));
