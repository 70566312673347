import React, {useState} from "react";
import {DialogManagerContext} from "../contexts/DialogManagerContext";

export const DialogManager = ({children}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [dialogComponent, setDialogComponent] = useState(null);
    const [context] = useState(() => ({
        openDialog: (factory) => {
            setIsOpen(true);
            setDialogComponent(
                factory({
                    open: true,
                    onClose: () => {
                        setIsOpen(false);
                        setDialogComponent(null);
                    },
                })
            );
        },
    }));

    return (
        <DialogManagerContext.Provider value={context}>
            {children}
            {isOpen && dialogComponent}
        </DialogManagerContext.Provider>
    );
};