import React from 'react';
import Navigation from '../Navigation';
import { getStaticData, changeSalesmanData } from '../../actions';
import { connect } from 'react-redux';
import LoaderView from '../loader/Loader';
import { withTranslation } from 'react-i18next';

class SalesmanData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      company: '',
      url: '',
      contactName: '',
      phoneNumber: '',
      login: '',
      account: '',
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!localStorage.token) return (window.location.pathname = '/');
    await this.props.getStaticData(localStorage.token).then(() => {
      this.setState({
        email: this.props.staticData.staticData.Email,
        company: this.props.staticData.staticData.CompanyName,
        url: this.props.staticData.staticData.WWW,
        account: this.props.staticData.staticData.AccountNumber,
        contactName: this.props.userData.token.user_name,
        phoneNumber: this.props.staticData.staticData.Phone,
        login: this.props.userData.token.user_email,
        isLoading: false,
      });
    });
  }

  render() {
    const { t } = this.props;
    if (this.state.isLoading) {
      return <LoaderView />;
    }
    return (
      <>
        <Navigation />
        <div id='page-wrapper'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='page-header'>
                <h1>{t("Seller's data")}</h1>
              </div>

              {/* <div className="alert alert-danger" role="alert"></div>

              <div className="alert alert-success" role="alert"></div> */}

              <form
                id='merchant-form'
                className='knk-form'
                method='post'
                disabled
              >
                <h4>{t("Log-in")}</h4>

                <table className='table table-hover'>
                  <tbody>
                    <tr>
                      <td width='40%'>{t("User")}</td>
                      <td>
                        <input
                          type='text'
                          id='pp_username'
                          name='pp_username'
                          disabled='disabled'
                          defaultValue={this.state.login}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h4>{t("Company's data")}</h4>

                <table className='table table-hover'>
                  <tbody>
                    <tr>
                      <td width='40%'>{t("Company")}</td>
                      <td>
                        <input
                          type='text'
                          id='pp_username'
                          name='pp_username'
                          disabled='disabled'
                          defaultValue={this.state.company}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td width='40%'>{t("WWW")}</td>
                      <td>
                        <input
                          type='text'
                          name='WWW'
                          id='pp_www'
                          disabled='disabled'
                          defaultValue={this.state.url}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td width='40%'>{t("Account number")}</td>
                      <td>
                        <input
                          type='text'
                          name='account'
                          id='pp_account'
                          disabled='disabled'
                          defaultValue={this.state.account}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h4>{t("Contact details")}</h4>

                <table className='table table-hover'>
                  <tbody>
                    <tr>
                      <td width='40%'>{t("First name and last name")}</td>
                      <td>
                        <input
                          type='text'
                          name='ContactName'
                          id='pp_name'
                          disabled='disabled'
                          defaultValue={this.state.contactName}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{t("E-mail")}</td>
                      <td>
                        <input
                          type='text'
                          id='pp_email'
                          name='Email'
                          disabled='disabled'
                          defaultValue={this.state.email}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{t("Phone number")}</td>
                      <td>
                        <input
                          type='text'
                          id='pp_phone'
                          name='Phone'
                          disabled='disabled'
                          defaultValue={this.state.phoneNumber}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className='col-lg-12 col-sm-12 col-xs-12 knk-table-btnbar text-center'>
                  <p>
                    {t("In the case of editing data, please contact")}
                    <span>
                      <a className="ml-1" href={"mailto:" + process.env.REACT_APP_SUPPORT_EMAIL}>
                        {process.env.REACT_APP_SUPPORT_EMAIL}
                      </a>
                    </span>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    staticData: state.staticData,
    changedSalesmanData: state.salesmanData,
  };
};

export default connect(mapStateToProps, {
  getStaticData,
  changeSalesmanData
})(withTranslation()(SalesmanData));
