import React from "react";
import Money from "../../atoms/Money";

const MoneyWithCurrency = ({value}) =>
    (
        <>
            <Money value={value} /> {process.env.REACT_APP_CURRENCY}
        </>
    )

export default MoneyWithCurrency;