import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {getResources, LANGUAGES} from "./constants/language";

i18n.use(initReactI18next)
    .init({
        fallbackLng: localStorage.getItem('lang') || process.env.REACT_APP_COUNTRY.toLowerCase(),
        resources: getResources(),
        debug: false,
        keySeparator: false,
        supportedLngs: LANGUAGES.map(el => el.id),
        nsSeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
