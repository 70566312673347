import React from 'react';
import {
    Modal as MuiModal,
    Fade,
    Backdrop,
    CircularProgress, Divider,
} from '@mui/material';
import {FaWindowClose} from 'react-icons/fa';
import * as S from './styles';
import Wrapper from "../../atoms/Wrapper/Wrapper";
import H4 from "../../atoms/Typography/H4";
import IconButton from "../../atoms/IconButton";

const Modal = ({
    children,
    title = '',
    isVisible = false,
    onClose = () => {},
    isLoading = false,
    size,
    scroll,
    sx,
}) => (
    <MuiModal
        open={isVisible}
        onClose={onClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        closeAfterTransition
    >
        <Fade in={isVisible}>
            <S.ModalBox size={size} sx={sx}>
                {
                    isLoading ? (
                        <Wrapper
                            alignItems='center'
                            justifyContent='center'
                            fullWidth
                        >
                            <CircularProgress />
                        </Wrapper>
                    ) : (
                        <>
                            <Wrapper
                                justifyContent='space-between'
                                directionRow
                                className={'mb-3'}
                            >
                                <H4 textAlign='left'>
                                    { title }
                                </H4>

                                <Wrapper alignItems='flex-end'>
                                    <IconButton
                                        sx={{outline: '0 !important'}}
                                        Icon={FaWindowClose}
                                        onClick={onClose}
                                    />
                                </Wrapper>
                            </Wrapper>
                            <Divider />
                            <Wrapper className={'mt-3'}>
                                {children}
                            </Wrapper>
                        </>
                    )
                }
            </S.ModalBox>
        </Fade>
    </MuiModal>
);

export default Modal;
