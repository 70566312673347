import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { withTranslation } from 'react-i18next';

import {
    orderDetails,
    modifyOrder,
    getConfirmedOrders,
} from '../../../actions';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Status from "../../Status";
import Spinner from "../../loader/Spinner";
import {ORDER_HISTORY_STATUSES} from "../../../constants/orderHistoryStatus";

class DetailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            targetAmount: '',
            isCancelOrder: false,
            isRefundOrder: false,
            isButtonsContainer: true,
            isRefundConfirmation: false,
            isRefundAmountValid: true,
            orderReturnInfo: undefined,
            isLoading: false,
        };
    }

    handleClose = () => {
        this.setState({ showModal: false });
    };

    numberMask = createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal: true,
        decimalSymbol: ',',
    });

    handleShow = async () => {
        let data = {
            orderId: this.props.orderID,
        };

        await this.props.orderDetails(data, localStorage.token).then(() => {
            this.setState({
                showModal: true,
                orderReturnInfo: this.props.process.detail.order.ReturnInfo,
            });
        });
    };

    handleTitle = () => {
        const { t } = this.props;

        return t('Transaction {{fullID}}',{fullID: this.props.process.detail.order.FullID});
    };

    formatDecimalAmountValue = value => value.toFixed(2).replace('.', ',');

    formatAmountInputValue = currentValue => {
        currentValue.length && this.setState({
            targetAmount: parseFloat(currentValue.replace(',', '.')).toFixed(2).replace('.', ','),
        });
    };

    countingLoan(loan) {
        const amount = loan / 100;
        return `${this.formatDecimalAmountValue(amount)} ${process.env.REACT_APP_CURRENCY}`;
    };

    btnCancelOrderShow = () => {
        this.setState({
            isButtonsContainer: false,
            isCancelOrder: true,
        });
    };

    btnCancelOrder = async () => {
        let data = {
            action: 'cancel',
            orderId: this.props.orderID,
        };
        
        await this.props.modifyOrder(data, localStorage.token).then(() => {
            let data = {
                orderId: this.props.orderID,
            };

            this.props.orderDetails(data, localStorage.token).then(() => {
                this.setState({ isCancelOrder: false });
            });
        });
    };

    btnRefundOrderShow = () => {
        this.setState({
            isButtonsContainer: false,
            isRefundOrder: true,
        });
    };

    btnRefundOrder = () => {
        let currentValue = parseInt(this.props.process.detail.order.CurrentLoan);
        let modifiedValue = parseInt(this.state.targetAmount.replace(',', '').replace('.',''));

        let data = {
            action: 'refund',
            amount: currentValue - modifiedValue,
            refundAmount: modifiedValue,
            orderId: this.props.orderID,
        };

        this.setState({ isLoading: true });

        this.props.modifyOrder(data, localStorage.token).then(() => {
            let data = {
                orderId: this.props.orderID,
            };

            this.props.orderDetails(data, localStorage.token).then(() => {
                this.setState({ isRefundConfirmation: false, targetAmount: '', isButtonsContainer: true, isLoading: false });
            });
        });
    };

    btnRefundConfirmation = () => {
        const { targetAmount } = this.state;
        const { CurrentLoan } = this.props.process.detail.order;

        const refundAmount = parseFloat(targetAmount.replace(',', '.'));

        if (refundAmount > 0 && refundAmount <= ( CurrentLoan / 100 )) {
            this.setState({
                isRefundAmountValid: true,
                isRefundOrder: false,
                isRefundConfirmation: true,
            });
        } else {
            this.setState({
                isRefundAmountValid: false,
            });
        }
    };

    onExit = () => {
        const {
            page,
            dateFrom,
            dateTo,
            filter
        } = this.props;

        let data = {
            page,
            orderStatus: null,
            dateFrom,
            dateTo,
            filter,
        };

        this.props.getConfirmedOrders(data, localStorage.token);
    };

    cancelOrder = () => {
        const { t } = this.props;

        if (this.state.isCancelOrder) {
            return (
                <>
                    <p>{t("Confirm order cancel")}</p>
                    <button
                        className="btn btn-sm btn-danger btnAction btnTransferSpec"
                        type="button"
                        value="cancel"
                        onClick={this.btnCancelOrder}>
                        <i className="fa fa-fw fa-trash-o"></i>
                        {t("Cancel order")}
                    </button>
                    <button
                        className="btn btn-sm btn-default btnAction"
                        type="button"
                        value="cancel"
                        onClick={this.backToDecisionCancel}>
                        <i className="fa fa-fw fa-retweet"></i>
                        {t("Undo")}
                    </button>
                </>
            );
        }
    };

    backToDecisionCancel = () => {
        this.setState({
            isCancelOrder: false,
            isButtonsContainer: true,
        });
    };

    cancelRefund = () => {
        this.setState({
            isRefundConfirmation: false,
            isButtonsContainer: true,
            targetAmount: '',
        });
    };

    confirmRefundOrder = () => {
        const { isRefundConfirmation, targetAmount } = this.state;
        const { t } = this.props;

        if (isRefundConfirmation) {
            return (
                <>
                    <p>
                        {t("Confirm refund")} -{' '}
                        <b>{targetAmount} {process.env.REACT_APP_CURRENCY}</b>?
                    </p>
                    <button
                        className="btn btn-sm btn-warning btnAction btnTransferSpec"
                        type="button"
                        value="cancel"
                        onClick={this.btnRefundOrder}>
                        <i className="fa fa-fw fa-retweet"></i>
                        {t("Order refund")}
                    </button>
                    <button
                        className="btn btn-sm btn-default btnAction"
                        type="button"
                        value="cancel"
                        onClick={this.cancelRefund}>
                        <i className="fa fa-fw fa-retweet"></i>
                        {t("Cancel refund")}
                    </button>
                </>
            );
        }
    };

    collectAmount = e => this.setState({ targetAmount: e.target.value });

    refundOrder = () => {
        const {
            isRefundOrder,
            targetAmount,
            isRefundAmountValid,
        } = this.state;

        const { CurrentLoan } = this.props.process.detail.order;

        const { t } = this.props;

        if (isRefundOrder) {
            return (
                <>
                    <span className="small">({t("refund amount")})</span>
                    <MaskedInput
                        name="targetAmount"
                        type="text"
                        placeholder="0,00"
                        mask={this.numberMask}
                        style={{ width: '100px' }}
                        className="form-control"
                        value={targetAmount}
                        onChange={this.collectAmount}
                        onBlur={e => this.formatAmountInputValue(e.target.value)}
                    />
                    {
                        !isRefundAmountValid &&
                        <p className="input-error-message">
                            {t("The refund amount should be between {{min}} and {{max}}",
                                {
                                    min: "0,01",
                                    max: this.countingLoan(CurrentLoan)
                                })}
                        </p>
                    }
                    <button
                        className="btn btn-sm btn-warning btnAction"
                        type="button"
                        value="refund"
                        style={{ marginTop: '5px' }}
                        onClick={this.btnRefundConfirmation}
                    >
                        <i className="fa fa-fw fa-retweet"></i>
                        {t("Return of goods")}
                    </button>
                </>
            );
        }
    };

    renderButtons = () => {
        const { MerchantOrderStatus, ReturnInfo } = this.props.process.detail.order;
        const { t } = this.props;

        if (
            MerchantOrderStatus === '1' ||
            MerchantOrderStatus === '4' ||
            (MerchantOrderStatus === '3' && ReturnInfo === '1') ||
            MerchantOrderStatus === '100'
        ) {
            return (
                <button
                    className="btn btn-warning btnTransferSpec"
                    onClick={this.btnRefundOrderShow}
                >
                    <i className="fa fa-fw fa-retweet"></i>
                    {t("Order refund")}
                </button>
            );
        } else if (
            MerchantOrderStatus === '0' ||
            MerchantOrderStatus === '10'
        ) {
            return (
                <button
                    className="btn btn-danger"
                    onClick={this.btnCancelOrderShow}>
                    <i className="fa fa-fw fa-trash-o"></i>
                    {t("Cancel")}
                </button>
            );
        }
    };

    handleModalBody = () => {
        const { t } = this.props;

        const {
            BaseLoan,
            CurrentLoan,
            MerchantOrderStatus,
            ForeignID,
            ReturnInfo
        } = this.props.process.detail.order;

        const {
            isButtonsContainer,
            isCancelOrder,
            isRefundOrder,
            isRefundConfirmation,
            isLoading,
        } = this.state;

        if (isLoading) {
            return <Spinner />;
        }

        return (
            <div>
                <p className="loan-display">
                    {t("Base/Initial amount")}{': '}
                    <b>
                        {this.countingLoan(BaseLoan)}
                    </b>
                </p>
                <p className="loan-display">
                    {t("Current amount")}{': '}
                    <b>
                        {this.countingLoan(CurrentLoan)}
                    </b>
                </p>
                <p className="loan-display">
                    {t("Status")}{': '}
                    <b>
                        <Status merchantOrderStatus={MerchantOrderStatus} returnInfo={ReturnInfo}/>
                    </b>
                </p>
                <p className="loan-display">
                    {t("Order number")}{': '}
                    <b>{ForeignID}</b>
                </p>
                <hr />
                {this.props.process.detail.orderHistory.map((orderHistoryItem, index) => {
                    return (
                        <p key={index}>
                            {orderHistoryItem.createdAt}{' '}
                            {`${t(ORDER_HISTORY_STATUSES.find((status) => status.id == orderHistoryItem.event.id)?.label)} `}
                            {orderHistoryItem.description ? ` ${orderHistoryItem.description.replace('.', ',')}` : ''}
                        </p>
                    );
                })}
                <hr />
                {isButtonsContainer && (
                    <div>{this.renderButtons()}</div>
                )}
                {isCancelOrder && this.cancelOrder()}
                {isRefundOrder && this.refundOrder()}
                {isRefundConfirmation && this.confirmRefundOrder()}
            </div>
        );
    };

    render() {
        return (
            <>
                <Button
                    variant=""
                    onClick={this.handleShow}
                    className="btn btn-link btnEditOrder"
                >
                    <i className="fa fa-bars" style={{ color: 'grey' }}></i>
                </Button>
                <Modal
                    show={this.state.showModal}
                    onHide={this.handleClose}
                    onEnter={this.getOrderDetail}
                    onExit={this.props.handleModal}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.showModal && this.handleTitle()}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <dl className="row">
                            {this.state.showModal && this.handleModalBody()}
                        </dl>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state, detailsOrder: state.orderDetails };
};

export default connect(mapStateToProps, {
    orderDetails,
    modifyOrder,
    getConfirmedOrders,
})(withTranslation()(DetailModal));
