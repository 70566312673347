import React from 'react';
import logo from './utilities/gfx/logo_PayPo_Kolor.png';
import { connect } from 'react-redux';
import {
  fetchUserToken,
  sendMailToChangePassword,
} from '../actions';
import { Field, reduxForm } from 'redux-form';
import merchantApi from '../apis/merchantApi'
import { withTranslation } from 'react-i18next';
import i18n from "i18next";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToNextPage: false,
      renderBanMessage: false,
      showModal: false,
      renderInvalidUser: false,
      invalidLoginData: false,
      renderEmailSent: false
    };
  }

  async componentDidMount() {
    if (localStorage.token) {
      await merchantApi.get('/getList', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }).then(response => {
        if(response.data.error){
          localStorage.clear()
        } else{
          window.location.pathname='/salesmanpanel'
        }
      })
    }
  }

  renderError({ error, touched }) {
    if (error && touched) {
      return <p className='text-danger'>{error}</p>;
    }
  }

  renderInput = ({ label, input, meta, type }) => {
    return (
        <>
          <label className='sr-only'>{label}</label>
          <input
              {...input}
              className='form-control'
              placeholder={label}
              type={type}
          />
          {this.renderError(meta)}
        </>
    );
  };

  onSubmit = (formValues) => {

    this.props.fetchUserToken(formValues).then(() => {
      if (localStorage.token && localStorage.token !== 'null' && localStorage.token !== 'undefined') {
        this.setState({ redirectToNextPage: true });
      } else if (this.props.userData.token === undefined) {
        this.setState({ invalidLoginData: true });
      } else if (this.props.userData.token.blocked === true) {
        this.setState({ renderBanMessage: true });
      }
    });

  };

  redirectHelper = () => {
    if (this.state.redirectToNextPage) {
      return window.location.pathname = '/choose-merchant';
    }
  };

  passwordReminder = () => {
    this.props.sendMailToChangePassword(
        this.props.form.loginModal.values.login
    ).then(() => {
      this.setState({
        renderEmailSent: true
      })
    });
    if (this.props.userData.token?.error === 'User not found') {
      this.setState({
        renderInvalidUser: true,
      });
    } else {
      this.setState({
        renderInvalidUser: false,
      });
    }
  };

  renderPasswordReminder = () => {
    const { t } = this.props;

    if (this.state.invalidLoginData) {
      return (
          <>
            <p className="text-danger">
              {t("Your login or password is incorrect")}
            </p>
            <p>
              <button
                  className='passwordReminder'
                  type='button'
                  onClick={this.passwordReminder}
              >
                {t("Change password")}
              </button>
            </p>
          </>
      );
    }
  };

  renderUnknownUser = () => {
    const { t } = this.props;

    return <p className='text-danger'>{t("User unknown")}</p>;
  };

  renderDropdown = ({ label, input }) => {
    const { t } = this.props;

    return (
        <>
          <label>{label}</label>
          <select {...input} id='shopList' className='form-control'>
            <option value=''>{t("Shop")}</option>
            {this.renderSelectOptions(this.props.userData.shopList)}
          </select>
        </>
    );
  };

  render() {
    const { t } = this.props;

    return (
        <div className='container-fluid login-wrapper'>
          <div className='card login'>
            <div className='card-body'>
              <img className='card-img' src={logo} alt='paypologo' />

              <h4 className='card-title'>{t("Transaction panel")}</h4>

              <form
                  className='form-signin'
                  method='post'
                  onSubmit={this.props.handleSubmit(this.onSubmit)}
              >
                <div className='form-group'>
                  <Field
                      name='login'
                      component={this.renderInput}
                      label={t("E-mail")}
                      type='text'
                  />
                </div>
                <div className='form-group'>
                  <Field
                      name='password'
                      component={this.renderInput}
                      label={t("Password")}
                      type='password'
                  />
                </div>
                {this.state.renderBanMessage && (
                    <p className='text-danger'>
                      {t("Account temporarily blocked.")}
                      <br />
                      {t("Try again in 30 minutes.")}
                    </p>
                )}
                {this.state.renderInvalidUser && this.renderUnknownUser()}
                {this.renderPasswordReminder()}
                {this.state.renderEmailSent && (
                    <p>{t("Password reset email has been sent")}</p>
                )}

                <button className='btn btn-lg btn-primary btn-block btn-pplogin'>
                  {t("Sign In")}
                </button>
                {this.redirectHelper()}
              </form>
            </div>
          </div>
        </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.login) {
    errors.login = i18n.t("Please fill in your e-mail address");
  }
  if (!formValues.password) {
    errors.password = i18n.t("Please fill in your password");
  }
  return errors;
};

const mapStateToProps = (state) => {
  return { ...state, token: state.token };
};

const componentWrapped = connect(mapStateToProps, {
  fetchUserToken,
  sendMailToChangePassword,
})(Login);

export default reduxForm({
  form: 'loginModal',
  validate: validate,
})(withTranslation()(componentWrapped));
