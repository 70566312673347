export default (state = {}, action) => {
  switch (action.type) {
    case 'GET_CONFIRMED_ORDERS':
      return { ...state, orders: action.payload };
    case 'GET_PAGINATION':
      return { ...state, pagination: action.payload };
    case 'GET_DICTIONARY':
      return { ...state, statusDictionary: action.payload };
    case 'GET_EXPORT_CONFIRMED_ORDERS':
      return { ...state, exportUrl: action.payload };
    case 'GET_REFUND_DICTIONARY':
      return { ...state, refundDictionary: action.payload };
    case 'GET_MERCHANT_GROUP':
      return { ...state, merchantGroup: action.payload };
    default:
      return state;
  }
};
