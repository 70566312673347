import React, {useState} from "react";
import Navigation from "../../Navigation";
import PageTitle from "../../molecules/PageTitle/PageTitle";
import {useTranslation} from "react-i18next";
import Wrapper from "../../atoms/Wrapper/Wrapper";
import TableWrapper from "../../molecules/TableWrapper/TableWrapper";
import {useTableColumnsTranslation} from "../../../hooks/useTableColumnsTranslation";
import {getColumns} from "./DataGridConfig";
import {useDialogManager} from "../../../contexts/DialogManagerContext";
import CorrectionDetailsModal from "./CorrectionDetailsModal/CorrectionDetailsModal";
import UploadCorrectionFileModal from "./UploadCorrectionFileModal";
import {useForm, FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../../atoms/Button";
import moment from "moment";
import getParsedZoneDate from "../../../services/parserDate";
import {useCorrectionFiles} from "../../../services/api/queries/useCorrections";
import {ROWS_PER_PAGE} from "../../../constants/apiSettings";
import CorrectionFilters from "./CorrectionFilters";

const initialValues = {
    dateFrom: getParsedZoneDate(moment().subtract(6, 'months'), 'YYYY-MM-DD'),
    dateTo: '',
    onlyFailedCorrections: false,
};

const CorrectionListNew = () => {
    const {t} = useTranslation();
    const {openDialog} = useDialogManager();
    const [isFiltered, setIsFiltered] = useState(false);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState(initialValues);

    const schema = yup.object().shape({
        dateFrom: yup.date().required(),
        dateTo: yup.date().when('dateFrom', {
            is: (value) => !!value && value !== '',
            then: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr).min(yup.ref('dateFrom'), t("End date should be later than from date")),
        }),
        onlyFailedCorrections: yup.bool(),
    });

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const {reset} = methods;
    const {data, isLoading, isFetching, refetch} = useCorrectionFiles(filters, page);

    const openUploadFileModal = () => () => {
        openDialog(({open, onClose}) =>
            <UploadCorrectionFileModal
                open={open}
                onClose={onClose}
                handleData={refetch}
            />
        );
    };

    const openDetailsModal = (correctionFileId) => () => {
        openDialog(({open, onClose}) =>
            <CorrectionDetailsModal
                open={open}
                onClose={onClose}
                correctionFileId={correctionFileId}
                onlyFailedCorrections={filters.onlyFailedCorrections}
            />
        );
    };

    const columns = useTableColumnsTranslation(
        getColumns(openDetailsModal).map((column) => ({
            ...column,
            sortable: false,
            disableColumnMenu: true,
        }))
    );

    const handleChangePage = (currentPage) => {
        setPage(currentPage);
    }

    const onSubmit = (data) => {
        const {dateFrom, dateTo, onlyFailedCorrections} = data;

        if (getParsedZoneDate(dateFrom, 'YYYY-MM-DD') !== initialValues.dateFrom
            || getParsedZoneDate(dateTo, 'YYYY-MM-DD') !== initialValues.dateTo
            || onlyFailedCorrections !== initialValues.onlyFailedCorrections
        ) {
            setFilters({
                dateFrom: getParsedZoneDate(dateFrom, 'YYYY-MM-DDT00:00:00'),
                dateTo: !!dateTo ? getParsedZoneDate(dateTo, 'YYYY-MM-DDT23:59:59') : '',
                onlyFailedCorrections: onlyFailedCorrections,
            });
            setIsFiltered(true);
            setPage(1);
        }
    }

    const handleReset = () => {
        setFilters(initialValues);
        setIsFiltered(false);
        setPage(1);
        reset();
    }

    const pages = !!data?.data.totalCount ? Math.ceil(data?.data.totalCount / ROWS_PER_PAGE) : 1;

    return (
        <>
            <Navigation />
            <Wrapper id="page-wrapper">
                <PageTitle title={t("List of corrections files")} />
                <Wrapper directionRow flexWrap justifyContent={"space-between"} sx={{rowGap: "10px"}}>
                    <Wrapper directionRow>
                        <FormProvider {...methods}>
                            <CorrectionFilters
                                handleReset={handleReset}
                                onSubmit={onSubmit}
                                isFiltered={isFiltered}
                            />
                        </FormProvider>
                    </Wrapper>
                    <Wrapper directionRow justifyContent={'flex-end'} className={'mb-4'}>
                        <Button
                            size={'s'}
                            onClick={openUploadFileModal()}
                        >
                            {t("Upload the correction file")}
                        </Button>
                    </Wrapper>
                </Wrapper>
                <TableWrapper
                    tableData={data?.data.items}
                    tableColumns={columns}
                    pages={pages}
                    currentPage={page}
                    handleChangePage={handleChangePage}
                    totalCount={data?.data.totalCount}
                    rowsPerPage={ROWS_PER_PAGE}
                    isLoading={isLoading || isFetching}
                />
            </Wrapper>
        </>
    );
}

export default CorrectionListNew;