import React from "react";
import { connect } from "react-redux";

import { Field, reduxForm, reset } from "redux-form";
import logo from "./utilities/gfx/logo_PayPo_Kolor.png";
import { changePassword } from "../actions";
import { withTranslation } from 'react-i18next';
import i18n from "i18next";

class PasswordReminder extends React.Component {

  componentDidMount(){
    this.props.dispatch(reset('changePasswordModal'));
  }

  renderError({ error, touched }) {
    if (error && touched) {
      return <p className="text-danger">{error}</p>;
    }
  }

  renderInput = ({ label, input, meta, type }) => {
    return (
      <>
        <label className="sr-only">{label}</label>
        <input
          {...input}
          className="form-control"
          placeholder={label}
          type={type}
        />
        {this.renderError(meta)}
      </>
    );
  };

  onSubmit = (formValues) => {
    let path = window.location.pathname
    let getHashFromURL = path.split("/passwordReset/").pop()
    this.props.changePassword(getHashFromURL, formValues.password, formValues.passwordRepeat)
      .then(() => {
        window.location.pathname = '/';
      })
      .catch((error) => {
        const { status } = error.response;
        const { message } = error.response.data;
        if(status === 400 && message === 'Hash is incorrect or password reset date expired'){
          window.location.pathname = '/session-expired';
        }
      })
  };

  render() {
    const { t } = this.props;

    return (
      <div className="container-fluid login-wrapper">
        <div className="card login">
          <div className="card-body">
            <img className="card-img" src={logo} alt="paypologo" />

            <h4 className="card-title">{t("Password change")}</h4>

            <form
              className="form-signin"
              method="post"
              name='resetForm'
              onSubmit={this.props.handleSubmit(this.onSubmit)}
            >
              <div className="form-group">
              <p className='password-info'>
                {t("The password should consist of")}{" "}
                <u>{t("at least")}</u>{": "}
                {t("8 characters, one lowercase letter, one uppercase, one special character, one number")}.</p>
                <Field
                  name="password"
                  component={this.renderInput}
                  label={t("Enter the password")}
                  type="password"
                />
              </div>
              <div className="form-group">
                <Field
                  name="passwordRepeat"
                  component={this.renderInput}
                  label={t("Repeat password")}
                  type="password"
                />
              </div>
              <button className="btn btn-lg btn-primary btn-block btn-pplogin">
                {t("Change your password")}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if(!/^(?=.*[a-z])/.test(formValues.password)){
    errors.password = i18n.t("Password must contain at least 1 lowercase letter")
  }

  if(!/^(?=.*[A-Z])/.test(formValues.password)){
    errors.password = i18n.t("Password must contain at least 1 uppercase letter")
  }
  if(!/^(?=.*\d)/.test(formValues.password)){
    errors.password = i18n.t("Password must contain at least 1 number")
  }
  if(!/^(?=.*[@$!%*#?&])/.test(formValues.password)){
    errors.password = i18n.t("The password must contain at least 1 special character")
  }

  if (!formValues.password) {
    errors.password = i18n.t("Please fill in the password")
  }

  if(!formValues.passwordRepeat){
    errors.passwordRepeat = i18n.t("Please fill in the password")
  }

  if (formValues.password !== formValues.passwordRepeat) {
    errors.passwordRepeat = i18n.t("The entered passwords do not match");
  }

  if(formValues.password?.length < 7){
    errors.password = i18n.t("Password is too short");
  }

  return errors;
};

const componentWrapped = connect(null, { changePassword })(PasswordReminder);

export default reduxForm({
  form: "changePasswordModal",
  validate: validate,
})(withTranslation()(componentWrapped));
