import styled from "styled-components";
import {Tab as MuiTab} from "@mui/material";
import {colors} from "../../../styles/variables";

export const Tab = styled(MuiTab)`
    color: ${colors.blackLowContrast};
    font-size: 1.4rem;
    font-family: 'Gilroy', sans-serif;
    
    &:focus {
        outline: 0;
    }

    &.Mui-selected {
        color: ${colors.black};
    }
`;