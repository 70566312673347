import merchantApi from '../apis/merchantApi';
import fileDownload from 'js-file-download'

import _ from 'lodash';
import axios from 'axios';
import {getMerchantId, getUserId} from "../services/getDataFromToken";
import getParsedZoneDate from "../services/parserDate";
import {toast} from "react-toastify";
import {t} from "i18next";

axios.interceptors.response.use(
    (res) => res,
    (error) => {
        const { status } = error.response;
        if (status === 400 || status === 403 || status >= 500) {
            return (window.location.pathname = '/error');
        } else if (status === 404) {
            return (window.location.pathname = '/not-found');
        }
    }
);

export const merchantShopList = (login, token) => async (dispatch) => {
    let data = JSON.stringify({
        filter: {
            merchant_user_email: login,
        },
    });

    const response = await merchantApi.post('/getMerchants', data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });

    dispatch({
        type: 'GET_SHOP_LIST',
        payload: response.data,
    });
};

export const merchantToken = (formValues) => async (dispatch) => {
    let data = JSON.stringify({
        email: formValues.login,
        merchant_id: parseInt(formValues.merchantId),
        password: formValues.password,
    });

    const response = await merchantApi.post('/getMarketplaceToken', data, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    dispatch({
        type: 'GET_USER_TOKEN',
        payload: response.data,
    });
};

export const fetchUserToken = (formValues) => async (dispatch) => {
    let data = JSON.stringify({
        username: formValues.login,
        password: formValues.password,
    });

    try {
        const response = await merchantApi.post('/getToken', data, {
            headers: {
                'Content-Type': 'text/plain',
            },
        });

        dispatch({
            type: 'GET_TOKEN',
            payload: response.data,
        });
    } catch (error) {
        if (error.response.status === 401) {
            dispatch({
                type: 'GET_TOKEN',
                payload: undefined,
            });
        }
    }
};

export const regenerateToken = (token, merchantId) => async (dispatch) => {
    let data = JSON.stringify({
        merchantId,
    });
    const response = await merchantApi.post('/regenerateToken', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    dispatch({
        type: 'REGENERATE_TOKEN',
        payload: response.data,
    });
};

export const sendMailToChangePassword = (email) => async (dispatch) => {
    let data = JSON.stringify({
        email: email,
    });

    await merchantApi
        .post('/requestRecoveryEmail', data, {
            headers: {
                'Content-type': 'application/json',
            },
        })
        .catch(function (error) {
            if (error.response) {
                dispatch({
                    type: 'INVALID_USER',
                    payload: error.response.data,
                });
            }
        });
};

export const changePassword = (
    token,
    password,
    confirmedPassword
) => async () => {
    let data = JSON.stringify({
        hash: token,
        password: password,
        repeatPassword: confirmedPassword,
    });

    await merchantApi.post('/resetPassword', data, {
        headers: {
            'Content-type': 'application/json',
        },
    });
};

export const logout = () => async (dispatch) => {
    dispatch({
        type: 'LOGOUT',
    });
};

export const flagStatement = (token) => async (dispatch) => {
    const response = await merchantApi.get('/getList', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    dispatch({
        type: 'GET_FLAG_STATEMENT',
        payload: response.data,
    });
};

export const googleChartData = (dateFrom, dateTo, token) => async (
    dispatch
) => {
    let data = JSON.stringify({
        dateFrom: dateFrom,
        dateTo: dateTo,
    });

    const response = await merchantApi.post(
        '/getBasicMonthlyStatistics',
        data,
        {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }
    );

    dispatch({
        type: 'GET_CHART_DATA',
        payload: response.data,
    });
};

export const getStaticData = (token) => async (dispatch) => {
    const response = await merchantApi.post(
        '/getMerchant',
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );

    dispatch({
        type: 'GET_API_SETTINGS',
        payload: response.data,
    });
};

export const changeSalesmanData = (data, token) => async (dispatch) => {
    const response = await merchantApi.post('/updateMerchant', data, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    dispatch({
        type: 'CHANGE_SALESMAN_DATA',
        payload: response.data,
    });
};

export const getUsers = (token) => async (dispatch) => {
    const response = await merchantApi.post(
        '/getUsers',
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );

    dispatch({
        type: 'GET_USERS',
        payload: _.map(response.data.users.items),
    });
    dispatch({
        type: 'GET_ROLES',
        payload: response.data.userRoles,
    });
};

export const getAccountancy = (token, data) => async (dispatch) => {
    const response = await merchantApi.post('/getAccountancy', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    dispatch({
        type: 'GET_ACCOUNTANCY',
        payload: response.data.transfers,
    });
    dispatch({
        type: 'GET_ACCOUNTANCY_PAGINATION',
        payload: response.data.lastPage,
    });
    dispatch({
        type: 'GET_MERCHANT_GROUP',
        payload: response.data.merchantGroup,
    });
};

export const getConfirmedOrders = (data, token) => async (dispatch) => {
    const response = await merchantApi.post('/getConfirmedOrders', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    dispatch({
        type: 'GET_CONFIRMED_ORDERS',
        payload: _.mapKeys(response.data.orders, 'ID'),
    });
    dispatch({
        type: 'GET_PAGINATION',
        payload: response.data.pagination,
    });
    dispatch({
        type: 'GET_DICTIONARY',
        payload: response.data.orderStatusDictionary,
    });
    dispatch({
        type: 'GET_MERCHANT_GROUP',
        payload: response.data.merchantGroup,
    });
    dispatch({
        type: 'GET_REFUND_DICTIONARY',
        payload: response.data.refundDictionary,
    });
};

export const getExportConfirmedOrders = (data, token) => async (dispatch) => {
    const response = await merchantApi.post('/exportConfirmedOrders', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    window.location.href = response.data.url;

    dispatch({
        type: 'GET_EXPORT_CONFIRMED_ORDERS',
        payload: response.data.url,
    });
};

export const getOnlineTransactions = (data, token) => async (dispatch) => {
    const response = await merchantApi.post('/getP3GTransactions', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    dispatch({
        type: 'GET_TRANSACTIONS',
        payload: _.mapKeys(response.data.transactions, 'id'),
    });
    dispatch({
        type: 'GET_TRANSACTIONS_PAGINATION',
        payload: response.data.pagination,
    });
    dispatch({
        type: 'GET_TRANSACTIONS_FILTERS',
        payload: response.data.filters,
    });
    dispatch({
        type: 'GET_MERCHANT_GROUP',
        payload: response.data.merchantGroup,
    });
};

export const getOnlineTransactionDetails = (data, token) => async (dispatch) => {
    const response = await merchantApi.post('/getOnlineTransactionDetails', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    dispatch({
        type: 'ONLINE_TRANSACTION_DETAILS',
        payload: response.data,
    });
};

export const getExportP3GTransactions = (data, token) => async (dispatch) => {
    const { status, dateFrom, dateTo, paymentMethod, reference } = data;

    await merchantApi
        .get("/exportP3GTransactions", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                status,
                dateFrom,
                dateTo,
                paymentMethod,
                reference,
            },
        })
        .then((res) => {
            if (res.status === 202) {
                toast.success(
                    t("The generated report will be sent to the email address")
                );
            }
        })
        .catch((error) => {
            if (error.response.status === 400) {
                toast.error(t(error.response.data.error));
            } else {
                toast.error(t("An unexpected error occurred"));
            }
        });

    dispatch({
        type: "GET_EXPORT_P3G_TRANSACTIONS",
    });

};

export const postP3GReturn = (data, token) => async (dispatch) => {
    await merchantApi.post('/p3gReturn', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getExportAccountancyTransfers = (data, token) => async (
    dispatch
) => {
    await merchantApi
        .post("/exportAccountancyTransfers", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res) => {
            if (res.status === 202) {
                toast.success(
                    t("The generated report will be sent to the email address")
                );
            }
        })
        .catch((error) => {
            if (error.response.status === 409) {
                toast.error(t("Your report is being generated, please wait for the email"));
            } else if (error.response.status === 400) {
                toast.error(t(error.response.data.error));
            } else {
                toast.error(t("An unexpected error occurred"));
            }
        });

    dispatch({
        type: 'GET_EXPORT_ACCOUNTANCY',
    });
};

export const getExportBatchAccountancyTransfers = (data, token) => async (
    dispatch
) => {
    await merchantApi
        .post("/exportBatchAccountancyTransfers", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then((res) => {
            if (res.status === 202) {
                toast.success(
                    t("The generated report will be sent to the email address")
                );
            }
        })
        .catch((error) => {
            if (error.response.status === 409) {
                toast.error(t("Your report is being generated, please wait for the email"));
            } else if (error.response.status === 400) {
                toast.error(t(error.response.data.error));
            } else {
                toast.error(t("An unexpected error occurred"));
            }
        });

    dispatch({
        type: 'GET_EXPORT_BATCH_ACCOUNTANCY',
    });
};

export const getCorrectionOrders = (data, token) => async (dispatch) => {
    const response = await merchantApi.post('/getCorrections', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

};

export const getLastTransfers = (token) => async (dispatch) => {
    const response = await merchantApi.post(
        '/getLastTransfers',
        {},
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
    dispatch({
        type: 'GET_LAST_TRANSFERS',
        payload: response.data,
    });
};

export const addUser = (data, token) => async (dispatch) => {
    const response = await merchantApi.post('/addUser', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    dispatch({
        type: 'ADD_USER',
        payload: response.data,
    });
};

export const updateUser = (data, token) => async (dispatch) => {
    const respose = await merchantApi.post('/updateUser', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    dispatch({
        type: 'UPDATE_USER',
        payload: respose.data,
    });
};

export const orderDetails = (data, token) => async (dispatch) => {
    const response = await merchantApi.post('/getOrderDetails', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    dispatch({
        type: 'ORDER_DETAILS',
        payload: response.data,
    });
};

export const processDetails = (id, token) => async (dispatch) => {
    const response = await merchantApi.get(`/processDetails/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    dispatch({
        type: 'PROCESS_DETAILS',
        payload: response.data,
    });
};

export const modifyOrder = (data, token) => async (dispatch) => {
    await merchantApi.post('/modifyOrder', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export const getSettlement = (data, token) => async (dispatch) => {
    const response = await merchantApi.post('/getSettlement', data, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    dispatch({
        type: 'GET_INVOICES',
        payload: response.data,
    });
}

export const downloadSettlementDocument = (url, fileName) => async (dispatch) => {
    axios.post(
        url,
        {},
        {
            headers: {
                'Authorization': `Bearer ${ localStorage.token }`,
            },
        })
        .then(response => {
            if (!!response.data.content) {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([response.data?.content]));
                link.setAttribute('download', `${fileName}.csv`);
                document.body.appendChild(link);
                link.click();
            } else {
                toast.error(t("There was an error downloading the file"));
            }
        });
};

export const downloadInvoiceDocument = (url, fileName) => async (dispatch) => {
    axios.post(
        url,
        {},
        {
            headers: {
                'Authorization': `Bearer ${ localStorage.token }`,
                'Content-Disposition': `attachment; filename=${ fileName }`
            },
        })
        .then(response => {
            window.open(response.data.url, '_blank').focus();
        });
};