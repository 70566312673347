import React, {useEffect, useState} from 'react';
import Modal from "../../../molecules/Modal";
import {useTranslation} from "react-i18next";
import Text from "../../../atoms/Typography/Text";
import MoneyWithCurrency from "../../../molecules/MoneyWithCurrency/MoneyWithCurrency";
import getParsedZoneDate from "../../../../services/parserDate";
import Wrapper from "../../../atoms/Wrapper/Wrapper";
import {Tab} from "../../../atoms/Tab/Tab";
import {Tabs} from "../../../atoms/Tabs/Tabs";
import TabPanel from "../../../atoms/TabPanel/TabPanel";
import VirtualizedTable from "../../../molecules/VirtualizedTable/VirtualizedTable";
import {Box} from "@mui/material";
import {useCorrectionFileDetails} from "../../../../services/api/queries/useCorrections";

const CorrectionDetailsModal = ({
    open,
    onClose,
    correctionFileId,
}) => {
    const {t} = useTranslation();
    const [correctionFileDetailsErrorData, setCorrectionFileDetailsErrorData] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);

    const {data, isLoading} = useCorrectionFileDetails(correctionFileId);

    const handleCorrectionWithError = () => {
        if(!data) {
            setCorrectionFileDetailsErrorData([]);
            return;
        }

        setCorrectionFileDetailsErrorData(data?.data.orders.filter((correction) => correction.status > 201));
    }

    const handleDataCount = (data) => {
        if (!data) {
            return 0;
        }

        return data.length;
    }

    const handleCorrectionDetailsStatus = (status) => {
        if (status === 0) {
            return t("Pending");
        } else if (status === 200 || status === 201) {
            return t("Processed");
        } else if (status >= 400) {
            return t("Error");
        }
    };

    const TABLE_COLUMNS_CONFIG = [
        {
            width: 80,
            label: t('ID'),
            dataKey: 'id',
        },
        {
            width: 160,
            label: t('Paypo ID'),
            dataKey: 'orderFullId',
            CellRenderer: ({value, row}) => <Text>{!!value ? value : row.orderTransactionId}</Text>,
        },
        {
            width: 340,
            label: t('Order number'),
            dataKey: 'orderForeignId',
        },
        {
            width: 160,
            label: t("Amount"),
            dataKey: "amount",
            CellRenderer: ({value}) => (<Text>-<MoneyWithCurrency value={value}/></Text>),
        },
        {
            width: 140,
            label: t("Status"),
            dataKey: "status",
            CellRenderer: ({value}) => (<Text>{handleCorrectionDetailsStatus(value)}</Text>),
        },
        {
            width: 180,
            label: t("Date"),
            dataKey: "modifiedAt",
            CellRenderer: ({value}) => (<Text>{getParsedZoneDate(value)}</Text>),
        },
    ]

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    }

    useEffect(() => {
        handleCorrectionWithError();
    }, [data])

    return (
        <Modal
            title={t("Correction file details")}
            isVisible={open}
            onClose={onClose}
            size="l"
            isLoading={isLoading}
        >
            <Wrapper sx={{minHeight: '75vh'}}>
                <Wrapper fullWidth>
                    <Tabs value={selectedTab} onChange={handleChangeTab} aria-label="nav tabs" variant="scrollable">
                        <Tab label={`${t('All')} (${handleDataCount(data?.data.orders)})`}></Tab>
                        <Tab label={`${t('Wrong')} (${handleDataCount(correctionFileDetailsErrorData)})`}></Tab>
                    </Tabs>
                    <TabPanel value={selectedTab} index={0}>
                        <Box style={{height: 'calc(75vh - 70px)', width: '100%', outline: '1px solid rgb(224,224,224)'}}>
                            {!!data &&
                                <VirtualizedTable
                                    rowCount={data?.data.orders?.length}
                                    rowGetter={({ index }) => data?.data.orders[index]}
                                    rowHeight={42}
                                    headerHeight={52}
                                    columns={TABLE_COLUMNS_CONFIG}
                                />
                            }
                        </Box>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <Box style={{height: 'calc(75vh - 70px)', width: '100%', outline: '1px solid rgb(224,224,224)'}}>
                            <VirtualizedTable
                                rowCount={handleDataCount(correctionFileDetailsErrorData)}
                                rowGetter={({ index }) => correctionFileDetailsErrorData[index]}
                                rowHeight={42}
                                headerHeight={52}
                                columns={TABLE_COLUMNS_CONFIG}
                            />
                        </Box>
                    </TabPanel>
                </Wrapper>
            </Wrapper>
        </Modal>
    );
}

export default CorrectionDetailsModal;