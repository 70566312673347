import React from 'react';
import Navigation from '../Navigation';
import {
    downloadInvoiceDocument,
    downloadSettlementDocument,
    getSettlement
} from '../../actions';
import { connect } from 'react-redux';
import LoaderView from '../loader/Loader';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { withTranslation } from 'react-i18next';

class Invoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dateFrom: moment(new Date()).subtract(6, 'months').toDate(),
            dateTo: new Date(),
            page: 1,
            lastPage: 1,
            merchantId: null,
            showMerchantGroup: false,
            showTable: false,
            specificationId: '',
            invoiceId: '',
        };
    }

    async componentDidMount() {
        if (!localStorage.token) return (window.location.pathname = '/');

        this.setState({
            merchantId: this.props.userData.token.user_id
                ? this.props.userData.token.user_id
                : localStorage.merchantId,
        });

        const filtersData = this.getFiltersDataFromState();

        await this.props
            .getSettlement(filtersData, localStorage.token)
            .then(() => {
                this.setState({ isLoading: false, showTable: true });
            });
    }

    downloadSettlementDocument = (url, filename) => () => {
        this.props.downloadSettlementDocument(url, filename);
    };

    downloadInvoiceDocument = (url, filename) => () => {
        this.props.downloadInvoiceDocument(url, filename);
    };

    getFiltersDataFromState = () => {
        const {
            dateFrom,
            dateTo,
            invoiceId,
            specificationId,
        } = this.state;

        return {
            dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(dateTo).format('YYYY-MM-DD'),
            invoiceId: invoiceId || null,
            specificationId: specificationId || null,
        }
    }

    handleDate(value, type) {
        this.setState({
            [type]: value,
        });
    }

    filterBtn = async () => {
        this.setState({ isLoading: true });

        const filtersData = this.getFiltersDataFromState(1);

        await this.props
            .getSettlement(filtersData, localStorage.token)
            .then(() => {
                 const { settlement } = this.props.invoices.invoices;
                 this.setState({ showTable: !!Object.keys(settlement).length })
            })
            .then(() => {
                this.setState({ isLoading: false });
            });
    };

    resetBtn = async () => {
        this.setState({
            isLoading: true,
            merchantId: ''
        });

        const dateFrom = moment(new Date()).subtract(6, 'months')

        const data = {
            dateFrom: dateFrom.format('YYYY-MM-DD'),
            dateTo: moment(new Date()).format('YYYY-MM-DD'),
            merchantId: null,
            invoiceId: null,
            specificationId: null,
        };

        await this.props
            .getSettlement(data, localStorage.token)
            .then(() => {
                const { settlement } = this.props.invoices.invoices;
                this.setState({ showTable: !!Object.keys(settlement).length })
            })
            .then(() => {
                this.setState({
                    dateFrom: dateFrom.toDate(),
                    dateTo: new Date(),
                    isLoading: false,
                    invoiceId: '',
                    specificationId: '',
                });
            });
    };

    renderList() {
        const { t } = this.props;

        let rows = [];
        if (
            this.props.invoices.invoices !== null &&
            this.props.invoices.invoices !== undefined
        ) {
            rows = this.props.invoices.invoices.settlement || [];
        }

        if (rows.length > 0) {
            return rows.map((settlementData, index) => {
                return (
                    <tr key={index}>
                        <td>{settlementData.number}</td>
                        <td>{settlementData.createdAt}</td>
                        <td className="text-center">
                            <button
                                className="btn btn-primary"
                                onClick={this.downloadSettlementDocument(settlementData.url, settlementData.number)}
                            >
                                <i className="fa fa-download"></i>
                            </button>
                        </td>
                        <td>{settlementData.invoice?.number || '-'}</td>
                        <td className="text-center">
                            {settlementData.invoice?.url ? (
                                <button
                                    className="btn btn-primary"
                                    onClick={this.downloadInvoiceDocument(settlementData.invoice?.url, settlementData.invoice?.number)}
                                >
                                    <i className="fa fa-download"></i>
                                </button>
                            ) : (
                                '-'
                            )}
                        </td>
                        <td title={ settlementData.downloadedAt || "" }>
                            { settlementData.downloadedAt ? t("YES") : t("NO") }
                        </td>
                        <td title={ settlementData.invoice?.downloadedAt || "" }>
                            { settlementData.invoice?.downloadedAt ? t("YES") : t("NO") }
                        </td>
                    </tr>
                );
            });
        }
    }

    renderAlert() {
        const { t } = this.props;

        if (!this.state.showTable)
            return (
                <div className="alert alert-warning" role="alert">
                    {t("No data meet the criteria")}
                </div>
            );
    }

    render() {
        const { t } = this.props;

        if (this.state.isLoading) {
            return <LoaderView />;
        }

        const {
            dateFrom,
            dateTo,
            specificationId,
            invoiceId
        } = this.state;

        return (
            <>
                <Navigation />
                <div id="page-wrapper">
                    <div className="row">
                        <div
                            className="col-sm-12"
                            style={{ paddingTop: '0px' }}>
                            <div className="page-header">
                                <h1>{t("Commission settlements")}</h1>
                                <div className="h1bb"></div>
                            </div>

                            <div className="input-set-container">
                                <p className="header">
                                    {t("Filtration")}
                                </p>
                                <div className="inputs">
                                    {this.state.showCompanyName && (
                                        <div className="input-group">
                                            <div className="input-label">
                                                {t("merchant")}
                                            </div>
                                            <select
                                                name="merchantId"
                                                className="form-control"
                                                id="selectMerchantId"
                                                defaultValue={this.state.merchantId}
                                                onChange={this.collectDataForSearchButton}
                                            >
                                                {
                                                    this.state.merchantGroup.map(({ CompanyName, MerchantId }) => (
                                                        <option value={MerchantId} key={CompanyName}>
                                                            {CompanyName}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    )}
                                    <div className="input-group">
                                        <div className="input-label">
                                            {t("from")}
                                        </div>
                                        <DatePicker
                                            name="dateFrom"
                                            id="selectDateFrom"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            selected={dateFrom}
                                            onChange={(value) =>
                                                this.handleDate(
                                                    value,
                                                    'dateFrom'
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="input-group">
                                        <div className="input-label">
                                            {t("until")}
                                        </div>
                                        <DatePicker
                                            name="dateTo"
                                            id="selectDateTo"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            selected={dateTo}
                                            onChange={(value) =>
                                                this.handleDate(
                                                    value,
                                                    'dateTo'
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="input-group">
                                        <div className="input-label">
                                            {t("specification")}
                                        </div>
                                        <input
                                            type="text"
                                            value={specificationId}
                                            id="specificationId"
                                            className="form-control"
                                            placeholder={t("Specification number")}
                                            onChange={e =>
                                                this.setState({
                                                    specificationId:
                                                    e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="input-group">
                                        <div className="input-label">
                                            {t("invoice")}
                                        </div>
                                        <input
                                            type="text"
                                            value={invoiceId}
                                            id="invoiceId"
                                            className="form-control"
                                            placeholder={t("Invoice number")}
                                            onChange={e =>
                                                this.setState({
                                                    invoiceId:
                                                    e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button
                                        className="btn btn-primary"
                                        id="btnSearch"
                                        type="button"
                                        onClick={this.filterBtn}
                                    >
                                        {t("Search")}
                                    </button>
                                    <button
                                        className="btn btn-default"
                                        value="Szukaj"
                                        type="button"
                                        onClick={this.resetBtn}
                                    >
                                        {t("Reset")}
                                    </button>
                                </div>
                            </div>
                            {this.state.showTable && (
                                <table
                                    className="table table-bordered table-td-middle"
                                    id="orders">
                                    <thead>
                                        <tr>
                                            <th>{t("Specification number")}</th>
                                            <th>{t("Date of issue of the invoice")}</th>
                                            <th>{t("Specification")}</th>
                                            <th>{t("Invoice number")}</th>
                                            <th>{t("Invoice")}</th>
                                            <th>{t("Specification downloaded")}</th>
                                            <th>{t("Invoice downloaded")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.renderList()}</tbody>
                                </table>
                            )}
                        </div>
                    </div>
                    {this.renderAlert()}
                    <div style={{ textAlign: 'right' }}>
                        {this.state.page > 1 && (
                            <button
                                className="passwordReminder"
                                onClick={this.previousPaginationClick}>
                                <i className="fa fa-arrow-left"></i>
                            </button>
                        )}

                        {t("Page {{page}} of {{lastPage}}", {page:this.state.page, lastPage:this.state.lastPage})}

                        {this.state.page < this.state.lastPage && (
                            <button
                                className="passwordReminder"
                                onClick={this.nextPaginationClick}>
                                <i className="fa fa-arrow-right"></i>
                            </button>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state, invoices: state.invoices };
};

export default connect(mapStateToProps, {
    getSettlement,
    downloadSettlementDocument,
    downloadInvoiceDocument,
})(withTranslation()(Invoices));
