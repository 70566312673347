import styled from 'styled-components';
import { colors, breakpoints } from '../../../styles/variables';
import { calculateModalSize } from '../../../components/utilities/calculateModalSize';

const calculateNotificationPosition = position => {
    switch (position) {
        case 'top':
            return `
                align-items: flex-start;
            `;
        case 'top left':
            return `
                align-items: flex-start;
                justify-content: flex-start;
            `;
        case 'top right':
            return `
                align-items: flex-start;
                justify-content: flex-end;
            `;
        case 'bottom left':
            return `
                align-items: flex-end;
                justify-content: flex-start;
            `;
        case 'bottom right':
            return `
                align-items: flex-end;
                justify-content: flex-end;
            `;
        case 'bottom':
        default:
            return `
                align-items: flex-end;
                justify-content: center;
            `;
    }
};

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 2.4rem 2.4rem 1.6rem;
    pointer-events: none;

    @media (min-width: ${ breakpoints.small }) {
        ${ ({ position }) => calculateNotificationPosition(position) };
    }

    @media (min-width: ${ breakpoints.medium }) {
        padding: ${ ({ size }) => size === 'l' ? '4.8' : '2.4' }rem;
    }

    @media (min-width: ${ breakpoints.big }) {
        padding: 4.8rem;
    }
`;

export const NotificationContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: ${ ({ maxHeight }) => maxHeight };
    border-radius: .8rem;
    background-color: ${ colors.white };
    box-shadow: ${ colors.paypoShadowGrey03 };
    pointer-events: all;
    
    @media (min-width: ${ breakpoints.small }) {
        max-width: ${ ({ size }) => calculateModalSize(size) };
    }
`;
