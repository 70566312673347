export const ORDER_HISTORY_STATUSES = [
    {
        id: 1,
        name: "ORDER NEW",
        label: "Paid",
    },
    {
        id: 2,
        name: "ORDER SENT",
        label: "Completed",
    },
    {
        id: 3,
        name: "ORDER REFUND",
        label: "Refund",
    },
    {
        id: 4,
        name: "ORDER CANCELED",
        label: "Canceled",
    },
    {
        id: 6,
        name: "ORDER CONFIRMED",
        label: "Order confirmed",
    },
    {
        id: 17,
        name: "ORDER REFUND PROCESSING",
        label: "Refund processing",
    },
];