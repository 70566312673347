import {combineReducers} from 'redux'
import {reducer as formReducer} from 'redux-form'
import tokenReducer from './tokenReducer'
import flagStatementReducer from './flagStatementReducer'
import chartDataReducer from './chartDataReducer'
import merchantStaticData from './merchantStaticData'
import changeSalesmanReducer from './changeSalesmanReducer'
import userReducer from './userReducer'
import confirmedOrdersReducer from './confirmedOrdersReducer'
import accountancyReducer from './accountancyReducer'
import modalReducer from './modalReducer'
import detailModalReducer from './detailModalReducer'
import onlineTransactionsReducer from './onlineTransactionsReducer'
import invoicesReducer from './invoicesReducer'

 const appReducer = combineReducers({
    form: formReducer,
    userData: tokenReducer,
    flagStatement: flagStatementReducer,
    googleChartData: chartDataReducer,
    staticData: merchantStaticData,
    changedSalesmanData: changeSalesmanReducer,
    users: userReducer,
    confirmedOrders: confirmedOrdersReducer,
    accountancy: accountancyReducer,
    modal: modalReducer,
    process: detailModalReducer,
    onlineTransactions: onlineTransactionsReducer,
    invoices: invoicesReducer
})

 const rootReducer = (state, action) => {
    if(action.type === 'LOGOUT'){
        state = undefined
    }

    return appReducer(state, action)
}

export default rootReducer;