import React from "react";
import { withTranslation } from 'react-i18next';
import { ORDER_STATUS } from "../constants/orderStatus";
import { RETURN_INFO } from "../constants/returnInfo";

class Status extends React.Component {

    render() {
        const { merchantOrderStatus, returnInfo } = this.props;

        let statusInfo = this.getStatus(merchantOrderStatus, returnInfo);

        return (
            <span className={ statusInfo.className }>
                { statusInfo.label }
            </span>
        );
    }

    checkRefund(returnInfo) {
        const { t } = this.props;

        switch (parseInt(returnInfo)) {
            case RETURN_INFO.FULL:
                return {label: t('Refund - full'), className: 'text-danger'};
            case RETURN_INFO.PARTIAL:
                return {label: t('Refund - partial'), className: 'text-danger'};
            default:
                return {label: t('Completed'), className: 'text-warning'};
        }
    }

    getStatus(merchantOrderStatus, returnInfo) {
        const { t } = this.props;

        switch (parseInt(merchantOrderStatus)) {
            case ORDER_STATUS.NEW:
                return {label: t('New'), className: 'text-info'};
            case ORDER_STATUS.PAID:
                return {label: t('Accepted'), className: 'text-warning'};
            case ORDER_STATUS.COMPLETED_OLD:
            case ORDER_STATUS.COMPLETED:
            case ORDER_STATUS.REFUND:
                return this.checkRefund(returnInfo);
            case ORDER_STATUS.CANCELED:
                return {label: t('Canceled'), className: 'text-danger'};
        }
    }
}

export default withTranslation()(Status);
