import React from 'react';
import {Box} from "@mui/material";

const TabPanel = ({children, value, index, ...rest}) => {

    return (<div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...rest}
    >
        {value === index && (
            <Box sx={{py: 2}}>
                {children}
            </Box>
        )}
    </div>);
}

export default TabPanel;