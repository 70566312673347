import styled from 'styled-components';

export const PageTitle = styled.h1`
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
    font-size: 4.2rem;
    line-height: 4.2rem;
    position: relative;
    margin: 0 0 1.6rem;
    z-index: 1;
    
    background-image: linear-gradient(#F8E8F3, #F8E8F3);
    background-repeat: no-repeat;
    background-position-y: 100%;
    display: inline-block;
    background-size: 100% 20px;
    
    text-align: ${
        ({ textAlign }) => textAlign
    };
`;