import React from 'react';
import Text from "../../atoms/Typography/Text";
import Wrapper from "../../atoms/Wrapper/Wrapper";
import {IconButton} from "@mui/material";
import {GrNext, GrPrevious} from "react-icons/all";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

const Pagination = ({
    dataLength,
    rowsPerPage,
    currentPage,
    onPageChange,
}) => {
    const {t} = useTranslation();

    const previousPage = () => {
        if (currentPage > 1) {
            onPageChange(--currentPage);
        }
    };

    const nextPage = () => {
        onPageChange(++currentPage);
    };

    const checkLastPage = () => {
        if (dataLength === 0 && currentPage > 1) {
            previousPage();
            toast.warn(t("This is the last page"));

            return true;
        }

        return dataLength < rowsPerPage;
    }

    return <Wrapper
        className={'pagination__container'}
        directionRow
    >
        <IconButton
            onClick={previousPage}
            disabled={currentPage === 1}
        >
            <GrPrevious />
        </IconButton>
        <Wrapper
            ssx={{
                padding: '3px 12px',
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                borderRadius: '3px',
            }}
        >
            <Text>
                {currentPage}
            </Text>
        </Wrapper>
        <IconButton
            onClick={nextPage}
            disabled={checkLastPage()}
        >
            <GrNext />
        </IconButton>
    </Wrapper>;
}

export default Pagination;
