import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import Navigation from "../../../Navigation";
import Wrapper from "../../../atoms/Wrapper/Wrapper";
import TableWrapper from "../../../molecules/TableWrapper/TableWrapper";
import PageTitle from "../../../molecules/PageTitle/PageTitle";
import {useTableColumnsTranslation} from "../../../../hooks/useTableColumnsTranslation";
import {getColumns} from "./P3GTransactionListDataGridConfig";
import {useP3GTransactions} from "../../../../services/api/queries/useTransactions";
import {P3G_TRANSACTION_LIST_ROWS_PER_PAGE} from "../../../../constants/apiSettings";
import P3GTransactionDetailsModal from "./P3GTransactionDetailsModal";
import {useDialogManager} from "../../../../contexts/DialogManagerContext";
import moment from "moment";
import getParsedZoneDate from "../../../../services/parserDate";

const initialValues = {
    canonicalId: "",
    providerCanonicalId: "",
    orderReference: "",
    merchantId: "",
    date: {
        startDate: getParsedZoneDate(moment().subtract(1, 'months'),'YYYY-MM-DD'),
        endDate: null,
    },
};
const P3GTransactionList = () => {
    const {t} = useTranslation();
    const {openDialog} = useDialogManager();
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState(initialValues);

    const {data, isLoading, isFetching, refetch} = useP3GTransactions(filters, page, P3G_TRANSACTION_LIST_ROWS_PER_PAGE);

    const validationSchema = yup.object().shape({
        canonicalId: yup.string().test('len', t("At least 3 characters"), val => val.length >= 3 || val.length === 0),
        providerCanonicalId: yup.string().test('len', t("At least 3 characters"), val => val.length >= 3 || val.length === 0),
        orderReference: yup.string().test('len', t("At least 3 characters"), val => val.length >= 3 || val.length === 0),
    });

    const methods = useForm({
        resolver:yupResolver(validationSchema),
        defaultValues: initialValues,
    });

    const onSubmit = (data) => {
        const {canonicalId, orderReference, providerCanonicalId, date} = data;

        setFilters({
            canonicalId,
            orderReference,
            providerCanonicalId,
            date,
        });
        setPage(1);
    }

    const openDetailsModal = (row) => () => {
        openDialog(({open, onClose}) =>
            <P3GTransactionDetailsModal
                open={open}
                onClose={onClose}
                refetch={refetch}
                transactionData={row}
            />
        );
    }

    const columns = useTableColumnsTranslation(
        getColumns(methods, data?.data.merchantGroup, openDetailsModal, initialValues.date).map((column) => ({
            ...column,
            sortable: false,
            disableColumnMenu: true,
        }))
    );

    const handleChangePage = (currentPage) => {
        if (currentPage >= 1) {
            setPage(currentPage);
        }
    }

    return (
        <>
            <Navigation />
            <Wrapper id="page-wrapper">
                <PageTitle title={t("Money transactions")} />
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <TableWrapper
                        className={"datagridHeader__filters"}
                        tableData={data?.data.transactions}
                        tableColumns={columns}
                        currentPage={page}
                        handleChangePage={handleChangePage}
                        rowsPerPage={P3G_TRANSACTION_LIST_ROWS_PER_PAGE}
                        isLoading={isLoading || isFetching}
                        headerHeight={90}
                    />
                </form>
            </Wrapper>
        </>
    );
}

export default P3GTransactionList;