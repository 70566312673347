import React from "react";
import {Stack} from "@mui/material";
import {Controller} from "react-hook-form";
import Text from "../../atoms/Typography/Text";

const DataGridHeaderWithInput = ({
    label,
    name,
    control,
    Input,
    InputProps,
    InputChildren,
}) => {
    return (
        <Stack sx={{gap: ".7rem", marginTop: ".8rem"}}>
            <Text bold sx={{marginBottom: "25px"}}>{label}</Text>
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, value}}) =>
                    <Input
                        onChange={onChange}
                        value={value}
                        size={"small"}
                        {...InputProps}
                    >
                        {InputChildren}
                    </Input>
                }
            />
        </Stack>
    );
}

export default DataGridHeaderWithInput;