import { useEffect } from 'react';
import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
    CaptureConsole as CaptureConsoleIntegration,
    ReportingObserver as ReportingObserverIntegration,
    ExtraErrorData as ExtraErrorDataIntegration,
} from '@sentry/integrations';

export const sentryConfig = {
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENVIRONMENT,
    release: process.env.RELEASE,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        }),
        new CaptureConsoleIntegration({
            // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
            levels: ['error'],
        }),
        new ReportingObserverIntegration({
            // default to ['crash', 'deprecation', 'intervention']
            types: ['crash'],
        }),
        new ExtraErrorDataIntegration({
            depth: 10,
        }),
    ],
    tracesSampleRate: 0.2, // (for example, to send 20% of transactions, set tracesSampleRate to 0.2)
    enabled: true,
};
