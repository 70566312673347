import styled from "styled-components";
import {colors} from "../../../../styles/variables";

export const SH6 = styled.h6`
    font-family: 'Gilroy', sans-serif;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 2.8rem;
    position: relative;
    margin: 0;
    z-index: 1;
    letter-spacing: .1rem;

    color: ${
        ({ colorGreen }) => colorGreen ? `${ colors.paypoGreen600 }` : `${ colors.black }`
    };

    text-align: ${
        ({ textAlign }) => textAlign
    };
`;
