import React from 'react';

import IconContent from './IconContent';

import {
    SButton,
    SLink,
} from './styles';

const Button = ({
    children,
    type,
    black = false,
    white = false,
    linkTo = '',
    size = 'm',
    iconType = false,
    Icon = false,
    ...props
}) => {
    const buttonClasses = `${ type } ${ black ? 'black' : white ? 'white' : '' }`;

    return (
        !!linkTo ? (
            <SLink
                className={ buttonClasses }
                href={ linkTo }
                size={ size }
                { ...props }
            >
                <IconContent
                    iconType={ iconType }
                    Icon={ Icon }
                >
                    { children }
                </IconContent>
            </SLink>
        ) : (
            <SButton
                className={ buttonClasses }
                size={ size }
                { ...props }
            >
                <IconContent
                    iconType={ iconType }
                    Icon={ Icon }
                >
                    { children }
                </IconContent>
            </SButton>
        )
    );
};

export default Button;
