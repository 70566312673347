import React from 'react';

import Button from '../../atoms/Button';

import * as S from './styles';

const ButtonsFactory = ({
    config = [],
    justifyContent = 'flex-start',
    disableBreakpoints = false,
    reverseOnMobile = false,
}) => (
    <S.ButtonsFactory
        justifyContent={ justifyContent }
        disableBreakpoints={ disableBreakpoints }
        reverseOnMobile={ reverseOnMobile }
    >
        {
            config.map((elem, i) => (
                <Button
                    key={ i }
                    { ...elem }
                >
                    { elem.name }
                </Button>
            ))
        }
    </S.ButtonsFactory>
);

export default ButtonsFactory;
