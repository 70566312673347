import React from 'react';
import { Link } from 'react-router-dom';
import sign from './utilities/gfx/sign.svg';
import i18n from "i18next";

const ErrorPage = () => {
    return (
        <div className="container-fluid error-wrapper">
            <div className="jumbotron">
                <div className="container" style={{ width: '960px' }}>
                    <div className="row">
                        <div className="col-md-6">
                            <img src={sign} alt="404" />
                        </div>
                        <div className="col-md-6">
                            <div className="error-message">
                                <h1 className="text-bold">{i18n.t("An unexpected error occurred")}</h1>
                                <div className="button-pill">
                                    <Link to="/">{i18n.t("Back to homepage")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ErrorPage;
