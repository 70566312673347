import React from 'react';
import Navigation from '../Navigation';
import {
    getAccountancy,
    getExportAccountancyTransfers,
    getExportBatchAccountancyTransfers,
} from '../../actions';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import LoaderView from '../loader/Loader';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

class Settlement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            dateFrom: moment(new Date()).subtract(1, 'months').toDate(),
            dateTo: new Date(),
            page: 1,
            lastPage: 1,
            merchantId: null,
            showMerchantGroup: false,
            showTable: false,
            ledgerId: '',
        };
    }

    handleExportCsv = (id) => {
        let data = {
            transferId: `${id}`,
        };
        this.props.getExportAccountancyTransfers(data, localStorage.token);
    };

    handleBatchExport = () => {
        const { dateFrom, dateTo, merchantId } = this.state;
        let data = {
            dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(dateTo).format('YYYY-MM-DD'),
            merchantId: merchantId
        }
        this.props.getExportBatchAccountancyTransfers(data, localStorage.token);
    }

    async componentDidMount() {
        if (!localStorage.token) return (window.location.pathname = '/');

        this.setState({
            merchantId: this.props.userData.token.user_id
                ? this.props.userData.token.user_id
                : localStorage.merchantId,
        });

        let data = {
            dateFrom: moment(this.state.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(this.state.dateTo).format('YYYY-MM-DD'),
            page: 1,
            merchantId: parseInt(
                this.props.userData.token.user_id
                    ? this.props.userData.token.user_id
                    : localStorage.merchantId
            ),
        };
        await this.props
            .getAccountancy(localStorage.token, data)
            .then(() => {
                if (this.props.accountancy.merchantGroup) {
                    if (
                        Object.values(this.props.accountancy.merchantGroup)
                            .length > 0
                    ) {
                        this.setState({
                            showMerchantGroup: true,
                            lastPage: this.props.accountancy
                                .accountancyPagination,
                        });
                    }
                }
            })
            .then(() => {
                this.setState({ isLoading: false, showTable: true });
            });
    }

    renderList() {
        let rows = [];
        if (
            this.props.accountancy.accountancy !== null &&
            this.props.accountancy.accountancy !== undefined
        ) {
            rows = this.props.accountancy.accountancy;
        }
        if (rows.length > 0) {
            return rows.map((settlementData) => {
                return (
                    <tr key={settlementData.id}>
                        <td>{settlementData.openingDate}</td>
                        <td>{settlementData.merchantName}</td>
                        <td>{settlementData.transferId}</td>
                        <td>{settlementData.closingDate}</td>
                        <td>
                            {(settlementData.amount / 100)
                                .toFixed(2)
                                .replace('.', ',')}
                        </td>

                        <td className="text-center">
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={() => this.handleExportCsv(settlementData.id)}
                            >
                                <i className="fa fa-download"></i>
                            </button>
                        </td>
                    </tr>
                );
            });
        }
    }

    renderAlert() {
        const { t } = this.props;

        if (!this.state.showTable)
            return (
                <div className="alert alert-warning" role="alert">
                    {t("No data meet the criteria")}
                </div>
            );
    }

    previousPaginationClick = async () => {
        let currentPage = this.state.page;
        currentPage = currentPage - 1;

        this.setState({
            page: currentPage,
            isLoading: true,
        });

        let data = {
            dateFrom: moment(this.state.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(this.state.dateTo).format('YYYY-MM-DD'),
            merchantId: parseInt(
                this.props.userData.token.user_id
                    ? this.props.userData.token.user_id
                    : localStorage.merchantId
            ),
            page: currentPage,
        };

        await this.props.getAccountancy(localStorage.token, data).then(() => {
            this.setState({
                lastPage: this.props.accountancy.accountancyPagination,
                isLoading: false,
            });
        });
    };

    resetBtn = async () => {
        this.setState({
            isLoading: true,
        });

        let data = {
            dateFrom: moment(new Date())
                .subtract(1, 'months')
                .format('YYYY-MM-DD'),
            dateTo: moment(new Date()).format('YYYY-MM-DD'),
            merchantId: parseInt(
                this.props.userData.token.user_id
                    ? this.props.userData.token.user_id
                    : localStorage.merchantId
            ),
            page: 1,
        };
        await this.props.getAccountancy(localStorage.token, data).then(() => {
            this.setState({
                isLoading: false,
                dateFrom: moment(new Date()).subtract(1, 'months').toDate(),
                dateTo: new Date(),
            });
        });
    };

    collectDataForSearchButton = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    nextPaginationClick = async () => {
        let currentPage = this.state.page;
        currentPage = currentPage + 1;

        this.setState({
            page: currentPage,
            isLoading: true,
        });

        let data = {
            dateFrom: moment(this.state.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(this.state.dateTo).format('YYYY-MM-DD'),
            merchantId: parseInt(
                this.props.userData.token.user_id
                    ? this.props.userData.token.user_id
                    : localStorage.merchantId
            ),
            page: currentPage,
        };
        await this.props.getAccountancy(localStorage.token, data).then(() => {
            this.setState({
                lastPage: this.props.accountancy.accountancyPagination,
                isLoading: false,
            });
        });
    };

    renderSelectOptions = () => {
        if (this.props.accountancy.merchantGroup) {
            let rows = Object.values(this.props.accountancy.merchantGroup);
            return rows.map((row) => {
                return (
                    <option key={row.MerchantId} value={row.MerchantId}>
                        {row.CompanyName}
                    </option>
                );
            });
        }
    };

    filterBtn = async () => {
        this.setState({
            isLoading: true,
        });
        let data = {
            dateFrom: moment(this.state.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(this.state.dateTo).format('YYYY-MM-DD'),
            merchantId: parseInt(this.state.merchantId),
            page: this.state.page,
            ledgerId: this.state.ledgerId,
        };
        await this.props.getAccountancy(localStorage.token, data).then(() => {
            this.setState({
                isLoading: false,
            });
        });
    };

    handleDate(value, type) {
        this.setState({
            [type]: value,
        });
    }

    render() {
        const { t } = this.props;

        if (this.state.isLoading) {
            return <LoaderView />;
        }

        return (
            <>
                <Navigation />
                <div id="page-wrapper">
                    <div className="row">
                        <div
                            className="col-sm-12"
                            style={{ paddingTop: '0px' }}>
                            <div className="page-header">
                                <h1>{t("Settlements")}</h1>
                                <div className="h1bb"></div>
                            </div>
                            <div className="input-set-container">
                                <p className="header">{t("Filtration")}</p>
                                <div className="inputs">
                                    {this.state.showMerchantGroup &&
                                        this.state.showTable && (
                                            <div className="input-group">
                                                <div className="input-label">
                                                    {t("shop")}
                                                </div>
                                                <select
                                                    name="merchantId"
                                                    className="form-control"
                                                    id="selectFilter"
                                                    defaultValue={
                                                        this.state.merchantId
                                                    }
                                                    onChange={
                                                        this
                                                            .collectDataForSearchButton
                                                    }>
                                                    {this.renderSelectOptions()}
                                                </select>
                                            </div>
                                        )}
                                    <div className="input-group">
                                        <div className="input-label">{t("from")}</div>
                                        <DatePicker
                                            name="dateFrom"
                                            id="selectDateFrom"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.dateFrom}
                                            onChange={(value) =>
                                                this.handleDate(
                                                    value,
                                                    'dateFrom'
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="input-group">
                                        <div className="input-label">{t("until")}</div>
                                        <DatePicker
                                            name="dateTo"
                                            id="selectDateTo"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            selected={this.state.dateTo}
                                            onChange={(value) =>
                                                this.handleDate(value, 'dateTo')
                                            }
                                        />
                                    </div>
                                    <div className="input-group">
                                        <div className="input-label">
                                            {t("settlement")}
                                        </div>
                                        <input
                                            type="text"
                                            value={this.state.ledgerId}
                                            id="ledgerId"
                                            className="form-control"
                                            placeholder={t("billing ID")}
                                            onChange={(e) =>
                                                this.setState({
                                                    ledgerId: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button
                                        className="btn btn-primary"
                                        id="btnSearch"
                                        type="button"
                                        onClick={this.filterBtn}>
                                        {t("Search")}
                                    </button>
                                    <button
                                        className="btn btn-default"
                                        value="Szukaj"
                                        type="button"
                                        onClick={this.resetBtn}>
                                        {t("Reset")}
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-12 text-right mt-2 mb-2">
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={this.handleBatchExport}>
                                    {t("Export to CSV file")}
                                    <i className="fa fa-download"></i>
                                </button>
                            </div>
                            {this.state.showTable && (
                                <table
                                    className="table table-bordered table-td-middle"
                                    id="orders">
                                    <thead>
                                        <tr>
                                            <th>{t("Data")}</th>
                                            <th>{t("Merchant")}</th>
                                            <th>{t("Transfer ID")}</th>
                                            <th>{t("Closing date")}</th>
                                            <th>{t("Amount")} ({process.env.REACT_APP_CURRENCY})</th>

                                            <th
                                                className="text-center"
                                                width="100">
                                                {t("Specification")}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.renderList()}</tbody>
                                </table>
                            )}
                        </div>
                    </div>
                    {this.renderAlert()}
                    <div style={{ textAlign: 'right' }}>
                        {this.state.page > 1 && (
                            <button
                                className="passwordReminder"
                                onClick={this.previousPaginationClick}>
                                <i className="fa fa-arrow-left"></i>
                            </button>
                        )}

                        {t("Page {{page}} of {{lastPage}}", {page:this.state.page, lastPage:this.state.lastPage})}

                        {this.state.page < this.state.lastPage && (
                            <button
                                className="passwordReminder"
                                onClick={this.nextPaginationClick}>
                                <i className="fa fa-arrow-right"></i>
                            </button>
                        )}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state, accountancy: state.accountancy };
};

export default connect(mapStateToProps, {
    getAccountancy,
    getExportAccountancyTransfers,
    getExportBatchAccountancyTransfers,
})(withTranslation()(Settlement));
