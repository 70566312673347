import React from 'react';
import { Link } from 'react-router-dom';
import logo from './utilities/gfx/logo_PayPo_Kolor.png';
import { connect } from 'react-redux';
import merchantApi from '../apis/merchantApi';
import { flagStatement, logout } from '../actions';
import { Accordion, Card } from 'react-bootstrap';
import LoaderView from './loader/Loader';
import { withTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import {COUNTRY} from "../constants/country";


class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            finishedTransaction: null,
            settlement: null,
            correctionlist: null,
            salesmanData: null,
            integration: null,
            users: null,
            userName: 'Username',
            userCompany: 'Company',
            logout: false,
            activeTransaction: false,
            activeSettings: false,
            activeOnline: false,
            isLoading: true,
        };
    }

    async componentDidMount() {
        await this.props.flagStatement(localStorage.token).then(() => {
            if (this.props.flags.error === 'Expired token') {
                return window.location.pathname = '/';
            } else {
                this.setState({
                    finishedTransaction: this.props.flags.ConfirmedOrders?.render,
                    settlement: this.props.flags.Accountancy?.render,
                    invoices: this.props.flags.Settlement?.render,
                    correctionlist: this.props.flags.CorrectionsOrders?.render,
                    salesmanData: this.props.flags.MerchantSettings?.render,
                    integration: this.props.flags.MerchantApiSettings?.render,
                    users: this.props.flags.Users?.render,
                    onlinePayments: this.props.flags.OnlinePayments?.render,
                    userName: localStorage.username
                        ? localStorage.username
                        : this.props.userData.token.user_name,
                    userCompany: localStorage.merchant
                        ? localStorage.merchant
                        : this.props.userData.token.merchant_name,
                    isLoading: false,
                });
            }
        });
    }

    clearLocalStorage = () => {
        Object.keys(localStorage).forEach((key) => {
            if('lang' !== key) {
                localStorage.removeItem(key);
            }
        });
    }

    handleLogout =  async () => {
        await merchantApi.post(
            '/logout',
            {},
            {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`,
                }
            })
            .finally(()=> {
                this.props.logout().then(() => {
                    this.clearLocalStorage();
                    this.setState({
                        logout: true,
                    });
                  });
            })
    };

    handleTrasnactionArrow = () => {
        this.setState({ activeTransaction: !this.state.activeTransaction });
    };

    handleOnlineTransactionsArrow = () => {
        this.setState({ activeOnline: !this.state.activeOnline });
    };

    handleSettingArrow = () => {
        this.setState({ activeSettings: !this.state.activeSettings });
    };

    redirectHelper = () => {
        if (this.state.logout) {
            return window.location.pathname = '/';
        }
    };

    render() {
        const { t } = this.props;

        if (this.state.isLoading) {
            return <LoaderView />;
        }

        return (
            <nav className="navbar navbar-default navbar-fixed-top knk-top-nav">
                <div className="navbar-header">
                    <Link to="/salesmanpanel" className="navbar-brand">
                        <span>
                            <img
                                src={logo}
                                alt="/"
                                style={{
                                    height: '36px',
                                    marginTop: '10px',
                                    marginLeft: '15px',
                                }}
                            />
                        </span>
                    </Link>
                </div>
                <ul className="nav navbar-nav navbar-right">
                    {process.env.REACT_APP_COUNTRY === COUNTRY.ROMANIA &&
                        <li>
                            <LanguageSwitcher />
                        </li>
                    }
                    <li>
                        <p>
                            <span>
                                <i className="fa fa-fw fa-user-o"></i>
                                {this.state.userName}
                            </span>
                        </p>
                        <p>
                            <span>
                                <i className="fa fa-fw fa-briefcase"></i>{' '}
                                {this.state.userCompany}
                            </span>
                        </p>
                    </li>
                    <li>
                        <button type="button" onClick={this.handleLogout}>
                            <i className="fa fa-power-off"></i>
                            {t("Logout")}
                        </button>
                    </li>
                </ul>
                {this.redirectHelper()}

                <div className="collapse navbar-collapse show">
                    <div id="sidebar-wrapper">
                        <div
                            className="navbar-default sidebar"
                            role="navigation">
                            <div className="sidebar-nav navbar-collapse">
                                <ul className="nav" id="side-menu">
                                    <li>
                                        <Accordion>
                                            <Card>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    variant="link"
                                                    eventKey="0"
                                                    onClick={
                                                        this
                                                            .handleTrasnactionArrow
                                                    }>
                                                    <i className="fa fa-handshake-o fa-fw "></i>
                                                    {t("Payment")}
                                                    <i
                                                        className={
                                                            this.state
                                                                .activeTransaction
                                                                ? 'fa fa-chevron-down arrow-down active'
                                                                : 'fa fa-chevron-down arrow-down'
                                                        }></i>
                                                </Accordion.Toggle>

                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <ul className="nav nav-second-level">
                                                            <li>
                                                                {this.state
                                                                    .finishedTransaction && (
                                                                    <Link to="/transactions/finishedtransactions">
                                                                        {t("Deferred transactions")}
                                                                    </Link>
                                                                )}
                                                                {this.state
                                                                    .onlinePayments && (
                                                                    <Link to="/transactions/p3g">
                                                                        {t("Money transactions")}
                                                                    </Link>
                                                                )}
                                                                {this.state
                                                                    .settlement && (
                                                                    <Link to="/transactions/settlement">
                                                                        {t("Settlements")}
                                                                    </Link>
                                                                )}
                                                                {this.state.correctionlist && (<Link to="/transactions/correction-list">
                                                                    {t("List of corrections/Mass refunds")}
                                                                </Link>
                                                                )}
                                                                {this.state
                                                                    .invoices && (
                                                                    <Link to="/transactions/invoices">
                                                                        {t("Invoice List")}
                                                                    </Link>
                                                                )}
                                                            </li>
                                                        </ul>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </li>
                                    <li>
                                        <Accordion>
                                            <Card>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    variant="link"
                                                    eventKey="0"
                                                    onClick={
                                                        this.handleSettingArrow
                                                    }>
                                                    <i className="fa fa-cog fa-fw"></i>
                                                    {t("Settings")}
                                                    <i
                                                        className={
                                                            this.state
                                                                .activeSettings
                                                                ? 'fa fa-chevron-down arrow-down active'
                                                                : 'fa fa-chevron-down arrow-down'
                                                        }></i>
                                                </Accordion.Toggle>

                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body>
                                                        <ul className="nav nav-second-level">
                                                            <li>
                                                                {this.state
                                                                    .salesmanData && (
                                                                    <Link to="/setting/salesmandata">
                                                                        {t("Seller's data")}
                                                                    </Link>
                                                                )}
                                                                {this.state
                                                                    .integration && (
                                                                    <Link to="/setting/integration">
                                                                        {t("Integration")}
                                                                    </Link>
                                                                )}
                                                                {this.state
                                                                    .users && (
                                                                    <Link to="/setting/users">
                                                                        {t("Users")}
                                                                    </Link>
                                                                )}
                                                            </li>
                                                        </ul>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        </Accordion>
                                    </li>
                                </ul>

                                <div className="bottom-wrapper">
                                    <div className="contact-data">
                                        <h4>{t("Technical contact")}:</h4>
                                        <span>
                                            <i className="fa fa-envelope-o"></i>
                                            <a className="ml-1" href={"mailto:" + process.env.REACT_APP_SUPPORT_EMAIL}>
                                                {process.env.REACT_APP_SUPPORT_EMAIL}
                                            </a>
                                        </span>

                                        <h4>{t("Business contact")}:</h4>
                                        <span>
                                            <i className="fa fa-envelope-o"></i>
                                            <a className="ml-1" href={"mailto:" + process.env.REACT_APP_BUSINESS_EMAIL}>
                                                {process.env.REACT_APP_BUSINESS_EMAIL}
                                            </a>
                                        </span>
                                    </div>
                                    {process.env.REACT_APP_URL ===
                                        'https://merchant.sandbox.paypo.pl' && (
                                            <div className="app-label">{t("Sandbox")}</div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state, flags: state.flagStatement.flags };
};

export default connect(mapStateToProps, { flagStatement, logout })(withTranslation()(Navigation));
