import React from "react";
import {useMutation} from "react-query";
import {uploadCorrectionFile} from "../services/correctionService";
import {toast} from "react-toastify";
import {t} from "i18next";

export const useCorrectionFiles = (onSuccess) => (
    useMutation({
        mutationFn: (payload) => uploadCorrectionFile(payload),
        onSuccess: () => {
            onSuccess();
        },
        onError: () => {
            toast.error(t('There was an error adding the file.'));
        },
    })
);