import styled from 'styled-components';
import { TextField } from '@mui/material';

export const DatePicker = styled(TextField)`
    width: initial;
    
    div > input {
        text-transform: uppercase;
        padding: 14px;
        font-size: 1.2rem;
    };
`;
