import merchantApi from "../../../apis/merchantApi";

export const getP3GTransactions = async (data, signal) => {
    return await merchantApi.post(
        '/getP3GTransactions',
        data,
        {
            signal: signal,
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        }
    );
}

export const getP3GTransactionDetails = async (data, signal) => {
    return await merchantApi.post(
        '/getOnlineTransactionDetails',
        data,
        {
            signal: signal,
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        }
    );
}

export const postTransactionReturn = async (data) => {
    return await merchantApi.post('/p3gReturn',
        data,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        });
};