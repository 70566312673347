import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';


import Navigation from '../Navigation';
import { getConfirmedOrders, getExportConfirmedOrders } from '../../actions';
import DetailModal from '../Transactions/dialog/DetailModal';
import LoaderView from '../loader/Loader';
import {ORDER_STATUS} from "../../constants/orderStatus";
import {RETURN_INFO} from "../../constants/returnInfo";
import Status from "../Status";

class FinishedTransaction extends React.Component {
    constructor(props) {
        super(props);

        const { user_id } = this.props.userData.token;

        this.state = {
            dateFrom: moment(new Date()).subtract(1, 'week').toDate(),
            dateTo: new Date(),
            orderStatus: null,
            page: 1,
            fullOrForeignId: '',
            ledgerId: '',
            lastPage: 1,
            isLoading: true,
            showAlert: false,
            showCompanyName: false,
            hasModalClosed: false,
            merchantGroup: [],
            merchantId: user_id ? user_id : localStorage.getItem('merchantId'),
        };
    }

    getFiltersDataFromState = (customPage) => {
        const {
            page,
            orderStatus,
            dateFrom,
            dateTo,
            ledgerId,
            merchantId,
            fullOrForeignId,
        } = this.state;

        let returnInfoValue;
        let orderStatusValue;

        switch (orderStatus) {
            case "3":
                orderStatusValue = ORDER_STATUS.REFUND;
                returnInfoValue = RETURN_INFO.FULL;
                break;
            case "4":
                orderStatusValue = ORDER_STATUS.REFUND;
                returnInfoValue = RETURN_INFO.PARTIAL;
                break;
            default:
                orderStatusValue = orderStatus;
                break;
        }

        return {
            page: customPage || page,
            orderStatus: parseInt(orderStatusValue),
            returnInfo: parseInt(returnInfoValue),
            dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(dateTo).format('YYYY-MM-DD'),
            merchantId: parseInt(merchantId),
            fullOrForeignId: fullOrForeignId || null,
            bankTransferLedgerId: ledgerId || null,
        };
    };

    async componentDidMount() {
        if (!localStorage.token) return (window.location.pathname = '/');

        this.setState({ isLoading: true });

        const filtersData = this.getFiltersDataFromState();
        
        await this.props
            .getConfirmedOrders(filtersData, localStorage.token)
            .then(() => {
                const { orders } = this.props.confirmedOrders;
                this.setState({ showAlert: !Object.keys(orders).length });
            })
            .then(() => {
                const { pagination } = this.props.confirmedOrders;

                if (pagination) {
                    this.setState({
                        page: pagination.currentPage,
                        lastPage: pagination
                            ? pagination.lastPage
                            : 1,
                    });
                }
            })
            .then(() => {
                const { merchantGroup } = this.props.confirmedOrders;
                if (
                    merchantGroup && merchantGroup.length > 1
                ) {
                    this.setState({
                        showCompanyName: true,
                        merchantGroup: merchantGroup
                    });
                }
            })
            .then(() => {
                this.setState({
                    isLoading: false,
                    merchantId: filtersData.merchantId
                });
            });
    }

    countingLoan(loan) {
        let amount = loan / 100;
        const correctAmount = amount.toFixed(2).replace('.', ',');
        return `${correctAmount} ${process.env.REACT_APP_CURRENCY}`;
    };

    handleSettlementStatus = (status, order) => {
        const { t } = this.props;

        if (status == 2) {
            return order.LedgerId;
        } else if (status == 1) {
            return t("To account");
        } else {
            return '-';
        }
    };

    handleCsvExport = () => {
        const { orderStatus, dateFrom, dateTo } = this.state;

        let data = {
            filter: orderStatus
                ? parseInt(orderStatus)
                : -1,
            dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(dateTo).format('YYYY-MM-DD'),
            brandGroupingShopId: -1,
        };

        this.props.getExportConfirmedOrders(data, localStorage.token);
    };

    handleModal = () => {
        this.setState({ hasModalClosed: true });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.hasModalClosed !== this.state.hasModalClosed) {
            this.setState({
                isLoading: true,
            });

            const filtersData = this.getFiltersDataFromState();

            this.props.getConfirmedOrders(filtersData, localStorage.token).then(() => {
                this.setState({ isLoading: false, hasModalClosed: false });
            });
        }
    }

    renderTableRow() {
        let rows = [];

        const { orders } = this.props.confirmedOrders;

        const {
            showCompanyName,
            page,
            dateFrom,
            dateTo,
            ledgerId
        } = this.state;

        if (
            orders !== null &&
            orders !== undefined
        ) {
            rows = Object.values(orders);
        }

        if (rows.length > 0) {
            return rows.reverse().map((order, index) => {
                return (
                    <tr className="orderRow" key={index}>
                        <td>
                            {order.FullID}<br/>
                            {order.TransactionId}
                        </td>
                        <td>{order.Created}</td>
                        {showCompanyName && (
                            <td>{order.CompanyName}</td>
                        )}
                        <td>{order.ForeignID}</td>
                        <td>{this.countingLoan(order.BaseLoan)}</td>
                        <td>{this.countingLoan(order.CurrentLoan)}</td>
                        <td>
                            <Status merchantOrderStatus={order.MerchantOrderStatus} returnInfo={order.ReturnInfo}/>
                        </td>
                        <td>
                            {this.handleSettlementStatus(
                                order.MerchantPaid,
                                order
                            )}
                        </td>
                        <td>
                            {(order.MerchantOrderStatus === '0' ||
                                order.MerchantOrderStatus === '1' ||
                                order.MerchantOrderStatus === '2' ||
                                order.MerchantOrderStatus === '3' ||
                                order.MerchantOrderStatus === '4' ||
                                order.MerchantOrderStatus === '10' ||
                                order.MerchantOrderStatus === '100') && (
                                <DetailModal
                                    handleModal={this.handleModal}
                                    orderID={order.ID}
                                    page={page}
                                    dateFrom={dateFrom}
                                    dateTo={dateTo}
                                    filter={ledgerId}
                                />
                            )}
                        </td>
                    </tr>
                );
            });
        }
    }

    filterBtn = async () => {
        this.setState({ isLoading: true });

        const filtersData = this.getFiltersDataFromState(1);

        await this.props
            .getConfirmedOrders(filtersData, localStorage.token)
            .then(() => {
                const { orders } = this.props.confirmedOrders;
                this.setState({ showAlert: !Object.keys(orders).length })
            })
            .then(() => {
                this.setState({
                    page: 1,
                    lastPage: this.props.confirmedOrders.pagination
                        ? this.props.confirmedOrders.pagination.lastPage
                        : 1,
                });
            })
            .then(() => {
                this.setState({ isLoading: false });
            });
    };

    resetBtn = async () => {
        this.setState({
            isLoading: true,
        });

        const data = {
            page: 1,
            orderStatus: null,
            dateFrom: moment(new Date())
                .subtract(1, 'week')
                .format('YYYY-MM-DD'),
            dateTo: moment(new Date()).format('YYYY-MM-DD'),
            merchantId: this.state.merchantId,
            returnInfo: null
        };

        await this.props
            .getConfirmedOrders(data, localStorage.token)
            .then(() => {
                if (
                    Object.keys(this.props.confirmedOrders.orders).length === 0
                ) {
                    this.setState({ showAlert: true });
                } else {
                    this.setState({ showAlert: false });
                }
            })
            .then(() => {
                const { pagination, merchantGroup } = this.props.confirmedOrders;
                this.setState({
                    page: 1,
                    orderStatus: null,
                    dateFrom: moment(new Date()).subtract(1, 'week').toDate(),
                    dateTo: new Date(),
                    isLoading: false,
                    ledgerId: '',
                    fullOrForeignId: '',
                    lastPage: pagination
                        ? pagination.lastPage
                        : 1,
                    merchantGroup: merchantGroup
                });
            });
    };

    collectDataForSearchButton = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    paginationClick = async (direction) => {
        this.setState({ isLoading: true });

        const { page } = this.state;

        let currentPage;

        switch (direction) {
            case 'previous':
                currentPage = page - 1;
                break;
            case 'next':
                currentPage = page + 1;
                break;
            default:
                currentPage = page;
        }

        this.setState({
            page: currentPage,
        });

        const filtersData = this.getFiltersDataFromState(currentPage);

        await this.props
            .getConfirmedOrders(filtersData, localStorage.token)
            .then(() => {
                this.setState({ isLoading: false });
            });
    };

    handleDate(value, type) {
        this.setState({
            [type]: value,
        });
    }

    render() {
        const { t } = this.props;

        const {
            isLoading,
            orderStatus,
            dateFrom,
            dateTo,
            fullOrForeignId,
            ledgerId,
            showAlert
        } = this.state;

        const orderStatusOptions = [
            {
                name: t("All"),
                value: null,
            },
            {
                name: t("New"),
                value: "0",
            },
            {
                name: t("Accepted"),
                value: "10",
            },
            {
                name: t("Completed"),
                value: "1",
            },
            {
                name: t("Canceled"),
                value: "2",
            },
            {
                name: t("Refund - full"),
                value: "3",
            },
            {
                name: t("Refund - partial"),
                value: "4",
            }
        ];

        if (isLoading) {
            return <LoaderView />;
        }

        return (
            <>
                <Navigation />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-header">
                                <h1>{t('Deferred transactions')}</h1>
                            </div>
                            <div className="input-set-container">
                                <p className="header">
                                    {t('Filtration')}
                                </p>
                                <div className="inputs">
                                    <div className="input-group">
                                        <div className="input-label">
                                            {t('status')}
                                        </div>
                                        <select
                                            name="orderStatus"
                                            className="form-control"
                                            id="selectFilter"
                                            defaultValue={orderStatus}
                                            onChange={this.collectDataForSearchButton}
                                        >
                                            {
                                                orderStatusOptions.map(({ name, value }) => (
                                                    <option value={value} key={name}>
                                                        {name}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                    {this.state.showCompanyName && (
                                        <div className="input-group">
                                            <div className="input-label">
                                                {t('merchant')}
                                            </div>
                                            <select
                                                name="merchantId"
                                                className="form-control"
                                                id="selectMerchantId"
                                                defaultValue={this.state.merchantId}
                                                onChange={this.collectDataForSearchButton}
                                            >
                                                {
                                                    this.state.merchantGroup.map(({ CompanyName, MerchantId }) => (
                                                        <option value={MerchantId} key={CompanyName}>
                                                            {CompanyName}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    )}
                                    <div className="input-group">
                                        <div className="input-label">
                                            {t("from")}
                                        </div>
                                        <DatePicker
                                            name="dateFrom"
                                            id="selectDateFrom"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            selected={dateFrom}
                                            onChange={(value) =>
                                                this.handleDate(
                                                    value,
                                                    'dateFrom'
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="input-group">
                                        <div className="input-label">
                                            {t("until")}
                                        </div>
                                        <DatePicker
                                            name="dateTo"
                                            id="selectDateTo"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            selected={dateTo}
                                            onChange={(value) =>
                                                this.handleDate(
                                                    value,
                                                    'dateTo'
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="input-group">
                                        <div className="input-label">
                                            {t("order")}
                                        </div>
                                        <input
                                            type="text"
                                            value={fullOrForeignId}
                                            disabled={ledgerId.length}
                                            id="fullOrForeignId"
                                            className="form-control"
                                            placeholder={t("Order ID")}
                                            onChange={e =>
                                                this.setState({
                                                    fullOrForeignId:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="input-group d-none">
                                        <div className="input-label">
                                            {t("settlement")}
                                        </div>
                                        <input
                                            type="text"
                                            value={ledgerId}
                                            disabled={fullOrForeignId.length}
                                            id="ledgerId"
                                            className="form-control"
                                            placeholder={t("billing ID")}
                                            onChange={e =>
                                                this.setState({
                                                    ledgerId:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button
                                        className="btn btn-primary"
                                        id="btnSearch"
                                        type="button"
                                        onClick={this.filterBtn}
                                    >
                                        {t("Search")}
                                    </button>
                                    <button
                                        className="btn btn-default"
                                        value="Szukaj"
                                        type="button"
                                        onClick={this.resetBtn}
                                    >
                                        {t("Reset")}
                                    </button>
                                </div>
                            </div>

                            <div
                                className={
                                    showAlert
                                        ? 'row display-none'
                                        : 'row'
                                }
                            >
                                <div className="col-md-12 text-right my-2">
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={this.handleCsvExport}
                                    >
                                        {t("Export to CSV file")}<i className="fa fa-download"></i>
                                    </button>
                                </div>
                            </div>

                            {showAlert ?
                                <div
                                    className="alert alert-warning"
                                    role="alert"
                                >
                                    {t("No data meet the criteria")}
                                </div> :
                                <div className="ordersList">
                                    <table
                                        className="table table-striped table-bordered table-sm table-td-middle"
                                        id="orders"
                                    >
                                        <thead>
                                            <tr>
                                                <th>{t("Transaction ID")}</th>
                                                <th>{t("Data")}</th>
                                                {this.state.showCompanyName && (
                                                    <th>{t("Merchant")}</th>
                                                )}
                                                <th>{t("Order number")}</th>
                                                <th>{t("Base/Initial amount")}</th>
                                                <th>{t("Current amount")}</th>
                                                <th>{t("Status")}</th>
                                                <th>{t("Settlement")}</th>

                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>{this.renderTableRow()}</tbody>
                                    </table>
                                    <div style={{ textAlign: 'right' }}>
                                        {this.state.page > 1 && (
                                            <button
                                                className="passwordReminder"
                                                onClick={() => this.paginationClick('previous')}
                                            >
                                                <i className="fa fa-arrow-left"></i>
                                            </button>
                                        )}

                                        {t("Page {{page}} of {{lastPage}}", {page:this.state.page, lastPage:this.state.lastPage})}

                                        {this.state.page < this.state.lastPage && (
                                            <button
                                                className="passwordReminder"
                                                onClick={() => this.paginationClick('next')}
                                            >
                                                <i className="fa fa-arrow-right"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state, confirmedOrders: state.confirmedOrders };
};

export default connect(mapStateToProps, {
    getConfirmedOrders,
    getExportConfirmedOrders,
})(withTranslation()(FinishedTransaction));
