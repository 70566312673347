import React from 'react';
import {createTheme} from "@mui/material";
import {colors} from "../../styles/variables";

export const CustomPaypoTheme = createTheme({
    components: {
        MuiButton: {
            variants: [
                {
                    props: {variant: 'primary'},
                    style: {
                        backgroundColor: colors.paypoGreen500,
                        color: colors.white,
                    }
                }
            ]
        }
    }
});