import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from '@sentry/react';
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import TagManager from 'react-gtm-module';

import {loadState, saveState} from './components/localStorage'
import App from "./App";
import { sentryConfig } from './utils/sentryConfig';
import rootReducer  from "./reducers";

import './i18n.js';

Sentry.init(sentryConfig);

const tagManagerArgs = {
    gtmId: 'GTM-WTLSGTW'
}

TagManager.initialize(tagManagerArgs)

require("dotenv").config();

// FIXME Remove by the end of June 2022, disconnect merchantbeta.paypo.pl domain from AWS container
if (window.location.host === "merchantbeta.paypo.pl") {
    window.location.href = process.env.REACT_APP_URL;
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedState = loadState()

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk))
);

store.subscribe(() => {
  saveState(store.getState())
})

// const container = document.getElementById('root');
// const root = createRoot(container);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root"));
