import React from 'react';
import Text from "../../atoms/Typography/Text";
import IconButtonWithTooltip from "../../molecules/IconButtonWithTooltip/IconButtonWithTooltip";
import {FaList} from "react-icons/all";
import {t} from "i18next";
import getParsedZoneDate from "../../../services/parserDate";
import {CORRECTION_FILE_STATUSES} from "../../../constants/correctionFileStatus";

export const getColumns = (openModal) => ([
    {
        headerName: 'ID',
        field: 'id',
        flex: 1,
        minWidth: 70,
        renderCell: ({row}) => {
            return (
                <Text>{row.id}</Text>
            );
        },
    },
    {
        headerName: 'Name',
        field: 'name',
        flex: 4,
        minWidth: 180,
        renderCell: ({row}) => {
            return (
                <Text>{row.name}</Text>
            );
        },
    },
    {
        headerName: 'Status',
        field: 'status',
        flex: 2,
        minWidth: 100,
        renderCell: ({row}) => {
            return (
                <Text>{t(CORRECTION_FILE_STATUSES.find(el => el.value === row.status)?.label)}</Text>
            );
        },
    },
    {
        headerName: 'Date',
        field: 'date',
        flex: 2,
        minWidth: 120,
        renderCell: ({row}) => {
            return (
                <Text>{getParsedZoneDate(row.createdAt)}</Text>
            );
        },
    },
    {
        headerName: 'User',
        field: 'user',
        flex: 3,
        minWidth: 160,
        renderCell: ({row}) => {
            return (
                <Text>{row.createdBy?.name}</Text>
            );
        },
    },
    {
        headerName: 'Actions',
        field: 'actions',
        flex: 1,
        minWidth: 70,
        renderCell: ({row}) => {
            return (
                <>
                    <IconButtonWithTooltip
                        tooltipText={t("Details")}
                        onClick={openModal(row.id)}
                        Icon={FaList}
                    />
                </>
            );
        },
    },
]);