export default (state = {}, action) => {
    switch(action.type){
        case 'GET_USERS':
            return {...state, users: action.payload}
        case 'GET_ROLES':
            return {...state, roles: action.payload}
        case 'ADD_USER':
            return {...state, addUser: action.payload}
        default:
            return state
    }
}