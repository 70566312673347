import React from "react";

import * as S from './styles';

const DatePicker = ({
    label,
    onChange,
    ...rest
}) =>
    <S.DatePicker
        label={label}
        type="date"
        InputLabelProps={{
            shrink: true,
        }}
        onChange={onChange}
        fullWidth
        {...rest}
    />

export default DatePicker;
