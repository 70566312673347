import jwtDecode from "jwt-decode";

export const getMerchantId = () => {
    const token = localStorage.getItem('token');
    if (!!token) {
        return jwtDecode(token)?.merchantId
    }
}

export const getUserId = () => {
    const token = localStorage.getItem('token');
    if (!!token) {
        return jwtDecode(token)?.userId
    }
}