import styled from "styled-components";
import {TextField as MuiTextField} from "@mui/material";
import {colors} from "../../../styles/variables";

export const TextField = styled(MuiTextField)`
    & .MuiFormLabel-root.Mui-focused {
        color: ${colors.paypoGreen600};
    }
  
    & .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${colors.paypoGreen600}; 
    }
`;