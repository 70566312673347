import React from "react";
import {Divider} from "@mui/material";
import * as S from './styles';
import Wrapper from "../../atoms/Wrapper/Wrapper";

const PageTitle = ({
    title,
    textAlign,
}) => (
    <Wrapper className={'mb-4'} sx={{ display: 'inline-block' }}>
        <S.PageTitle textAlign={textAlign}>
            {title}
        </S.PageTitle>
        <Divider />
    </Wrapper>
)

export default PageTitle;