import React, {useState} from 'react';
import Modal from "../../molecules/Modal";
import {useTranslation} from "react-i18next";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Button as MuiButton, TextField} from "@mui/material";
import Text from "../../atoms/Typography/Text";
import Wrapper from "../../atoms/Wrapper/Wrapper";
import {colors} from "../../../styles/variables";
import H6 from "../../atoms/Typography/H6";
import {FaFileAlt} from "react-icons/all";
import {useCorrectionFiles} from "../../../services/api/mutations/useCorrections";
import LoadingButton from "../../molecules/LoadingButton/LoadingButton";

const UploadCorrectionFileModal = ({
    open,
    onClose,
    handleData,
}) => {
    const {t} = useTranslation();
    const [selectedFileName, setSelectedFileName] = useState("");

    const schema = yup.object().shape({
        file: yup.mixed()
            .test("required", t("You need to provide a file"), (file) => {
                return !!file?.length;
            })
            .test("type", t("You can upload only CSV file"), (file) => {
                return !!file && file[0].type === 'text/csv';
            })
            .test("size", t("Your file is too big. Max 5MB"), (file) => {
                return !!file && file[0].size <= 5000000;
            })
    });

    const {control, handleSubmit, formState:{errors}} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            file: '',
        }
    });

    const handleOnChange = (e) => {
        setSelectedFileName(e.target.files[0]?.name);
    }

    const onSuccess =  () => {
        onClose();
        handleData();
    }

    const uploadCorrectionFileMutation = useCorrectionFiles(onSuccess);

    const onSubmit = (files) => {
        const formData = new FormData();
        formData.append('file', files.file[0]);

        uploadCorrectionFileMutation.mutate(formData);
    };

    return (
        <Modal
            title={t("Upload the correction file")}
            isVisible={open}
            onClose={onClose}
            size="s"
        >
            {!!selectedFileName &&
                <Wrapper
                    fullWidth
                    directionRow
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '1rem',
                        gap: '1rem',
                    }}
                >
                    <FaFileAlt size={20}/>
                    <Text
                        bold
                    >
                        {selectedFileName}
                    </Text>
                </Wrapper>
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="file"
                    control={control}
                    render={({field: {onChange, value}}) =>
                        <Wrapper
                            sx={{
                                borderRadius: '4px',
                                border: `2px dashed ${colors.border}`,
                                backgroundColor: colors.paypoGrey50,
                                margin: '2rem 0 1rem',
                            }}
                        >
                            <MuiButton
                                component="label"
                                fullWidth
                                sx={{
                                    padding: '5rem 0',
                                }}
                            >
                                <H6>
                                    {t('Click to add a file')}
                                </H6>
                                <TextField
                                    hidden
                                    type='file'
                                    value={value.filename}
                                    onChange={(e) => {
                                        onChange(e.target?.files);
                                        handleOnChange(e)
                                    }}
                                />
                            </MuiButton>
                        </Wrapper>
                    }
                />
                {!!errors.file && <Text bold type={'paypoFuchsia500'} fontSize={'1rem'}>{errors.file?.message}</Text>}
                <Wrapper directionRow justifyContent={'flex-end'} fullWidth sx={{marginTop: '2rem'}}>
                    <LoadingButton
                        isLoading={uploadCorrectionFileMutation.isLoading}
                        disabled={uploadCorrectionFileMutation.isLoading}
                        label={t("Upload")}
                    />
                </Wrapper>
            </form>
        </Modal>
    );
}

export default UploadCorrectionFileModal;