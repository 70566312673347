import React from 'react';
import Navigation from '../Navigation';
import { connect } from 'react-redux';
import { getStaticData } from '../../actions';
import LoaderView from '../loader/Loader';
import { withTranslation } from 'react-i18next';
import { API_DOCUMENT_FILE } from '../../constants/apiDocumentFileInfo'

class Integration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiV3Id: '',
            apiV3Secret: '',
            isLoading: true,
        };
    }

    async componentDidMount() {
        if (!localStorage.token) return (window.location.pathname = '/');
        await this.props.getStaticData(localStorage.token).then(() => {
            this.setState({
                apiV3Id: this.props.staticData.staticData.ExternalId,
                apiV3Secret: this.props.staticData.staticData.secret
                    ? this.props.staticData.staticData.secret
                    : '',
                isLoading: false,
            });
        });
    }
    render() {
        const { t } = this.props;

        if (this.state.isLoading) {
            return <LoaderView />;
        }
        return (
            <>
                <Navigation />
                <div id="page-wrapper">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-header">
                                <h1>API {API_DOCUMENT_FILE.version} </h1>
                            </div>

                            <form id="api-form" className="knk-form">
                                <h4>{t("Documentation")}</h4>

                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <i className="fa fa-lg fa-border fa-file-pdf-o"></i>
                                                <a
                                                    href={API_DOCUMENT_FILE.url.pl}
                                                    target="_blank"
                                                >
                                                    PayPo - Specyfikacja API {API_DOCUMENT_FILE.version} (PL)
                                                </a>
                                                <br />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <i className="fa fa-lg fa-border fa-file-pdf-o"></i>
                                                <a
                                                    href={API_DOCUMENT_FILE.url.en}
                                                    target="_blank"
                                                >
                                                    PayPo - API Specification {API_DOCUMENT_FILE.version} (EN)
                                                </a>
                                                <br />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4>{t("Configuration")}</h4>

                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <td width="40%">{t("Identifier")}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    id="pp_merchant_id"
                                                    name="pp_merchant_id"
                                                    disabled="disabled"
                                                    value={this.state.apiV3Id}
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td width="40%">{t("Token")}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    id="pp_token"
                                                    name="pp_token"
                                                    disabled="disabled"
                                                    value={
                                                        this.state.apiV3Secret
                                                    }
                                                />
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>{t("URL")}</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    id="pp_url"
                                                    name="pp_url"
                                                    disabled="disabled"
                                                    value={
                                                        process.env
                                                            .REACT_APP_API_V3
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state, staticData: state.staticData };
};

export default connect(mapStateToProps, { getStaticData })(withTranslation()(Integration));
