import React from 'react';

import H4 from '../../atoms/Typography/H4';
import CloseButton from '../../atoms/CloseButton';
import ButtonsFactory from '../../molecules/ButtonsFactory';

import * as S from './styles';

const ModalTemplate = ({
    children,
    title,
    maxHeight = "100vh",
    size = "m",
    withoutFooter = false,
    withoutCloseButton = false,
    footerConfig = {},
    handleClose,
}) => {
    const DEFAULT_FOOTER_CONFIG = {
        buttonsData: [
            {
                name: "Zamknij",
                onClick: () => handleClose(),
                type: "white",
                size: "s/m",
            },
        ],
        buttonsAlign: "flex-end",
        reverseOnMobile: false,
    };

    return (
        <S.TemplateContainer
            maxHeight={ maxHeight }
            size={ size }
        >
            <S.Header>
                <H4
                    withMargin={ false }
                    textAlign="left"
                >
                    { title }
                </H4>
                
                {
                    !withoutCloseButton && (
                        <CloseButton onClick={ handleClose } className="ml-s" />
                    )
                }
            </S.Header>

            <S.ContentContainer withoutFooter={ withoutFooter }>
                <S.Content withoutFooter={ withoutFooter }>
                    { children }
                </S.Content>
            </S.ContentContainer>

            {
                !withoutFooter && (
                    <S.Footer>
                        <ButtonsFactory
                            config={
                                footerConfig.buttonsData ||
                                DEFAULT_FOOTER_CONFIG.buttonsData
                            }
                            justifyContent={
                                footerConfig.buttonsAlign ||
                                DEFAULT_FOOTER_CONFIG.buttonsAlign
                            }
                            reverseOnMobile={ 
                                footerConfig.reverseOnMobile ||
                                DEFAULT_FOOTER_CONFIG.reverseOnMobile
                            }
                        />
                    </S.Footer>
                )
            }
        </S.TemplateContainer>
    );
};
 
export default ModalTemplate;
