import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {getOnlineTransactionDetails, postP3GReturn} from '../../actions';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import MaskedInput from 'react-text-mask';
import { withTranslation } from 'react-i18next';
import i18n from "i18next";

class P3GModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            targetAmount: '',
            isRefundConfirmation: false,
            isRefundAmountValid: true,
            showCompleted: false,
            orderReturnInfo: undefined
        };
    }

    handleClose = () => {
        this.setState({ showModal: false });
    };

    numberMask = createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal: true,
        decimalSymbol: ',',
    });

    handleShow = async () => {
        const data = {
            ledgerId: this.props.transactionId,
        };

        await this.props.getOnlineTransactionDetails(data, localStorage.token).then(() => {
            this.setState({
                showModal: true,
                orderReturnInfo: this.props.process.detail
            });
        });
    };

    handleTitle = () => {
        const { t } = this.props;

        return t("Transaction {{fullID}}",{fullID: this.props.paymentId});
    };

    formatDecimalAmountValue = value => value.toFixed(2).replace('.', ',');

    countingAmount(loan) {
        const amount = loan / 100;
        return `${this.formatDecimalAmountValue(amount)} ${process.env.REACT_APP_CURRENCY}`;
    }

    btnRefundConfirmation = () => {
        const { targetAmount } = this.state;
        const { currentAmount } = this.props;

        const refundAmount = parseFloat(targetAmount.replace(',', '.'));

        if (refundAmount > 0 && refundAmount <= ( currentAmount / 100 )) {
            this.setState({
                isRefundAmountValid: true,
                isRefundOrder: false,
                isRefundConfirmation: true,
            });
        } else {
            this.setState({
                isRefundAmountValid: false,
            });
        }
    };

    formatAmountInputValue = currentValue => {
        currentValue.length && this.setState({
            targetAmount: parseFloat(currentValue.replace(',', '.')).toFixed(2).replace('.', ','),
        });
    };

    collectAmount = (e) => this.setState({ targetAmount: e.target.value });

    operationDictionary = status => {
        const { t } = this.props;

        const statusList = {
            1: t("New order"),
            3: t("Order return"),
        };

        return statusList[status];
    };

    handleModalBody = () => {
        const { targetAmount, isRefundAmountValid } = this.state;
        const { t } = this.props;

        return (
            <div>
                <p className="loan-display">
                    {t("Base/Initial amount")}{': '}
                    <b>
                        {this.countingAmount(this.props.baseAmount)}
                    </b>
                </p>
                <p className="loan-display">
                    {t("Current amount")}{': '}
                    <b>
                        {this.countingAmount(this.props.currentAmount)}
                    </b>
                </p>
                <p className="loan-display">
                    {t("Status")}{': '}
                    <b>
                        {this.props.status}
                    </b>
                </p>
                <p className="loan-display">
                    {t("Order number")}{': '}
                    <b>
                        {this.props.referenceId}
                    </b>
                </p>
                <hr />
                {this.props.process.detail.map((ledger, index) => (
                        <p key={index}>
                            {ledger.Created}{' '}
                            {this.operationDictionary(ledger.Operation)}{' '} {this.countingAmount(ledger.Amount)}
                        </p>
                    )
                )}
                <hr />
                <br />

                <p className="loan-display">
                    {t("Enter amount of refund")}{": "}
                </p>
                <MaskedInput
                    name="targetAmount"
                    type="text"
                    placeholder="0,00"
                    mask={this.numberMask}
                    style={{ width: '100px' }}
                    className="form-control"
                    value={targetAmount}
                    onChange={this.collectAmount}
                    onBlur={(e) => this.formatAmountInputValue(e.target.value)}
                />
                {!isRefundAmountValid && (
                    <p className="input-error-message">
                        {t("The refund amount should be between {{min}} and {{max}}",
                            {
                                min: '0,01',
                                max: this.countingAmount(this.props.currentAmount)
                            })
                        }
                    </p>
                )}
                <button
                    className="btn btn-sm btn-warning btnAction"
                    type="button"
                    value="refund"
                    style={{ marginTop: '5px' }}
                    onClick={this.btnRefundConfirmation}>
                    <i className="fa fa-fw fa-retweet"></i> {t("Order refund")}
                </button>
            </div>
        );
    };

    cancelRefund = () => {
        this.setState({
            isRefundConfirmation: false,
            targetAmount: '',
        });
    };

    btnRefundOrder = () => {
        const data = {
            transactionId: this.props.transactionId,
            amount: parseInt((parseFloat(this.state.targetAmount.replace(',', '.')) * 100).toString()),
            current: this.props.currentAmount
        }

        this.props.postP3GReturn(data, localStorage.token).then(() => {
            this.setState({showCompleted: true, isRefundConfirmation: false})
        }).then(() => {
            setTimeout(()=> {
                this.setState({targetAmount: '', showModal: false})
            }, 3000)
        })
    }

    refundCompleted = () => (
        <p className="loan-display">
            {
                i18n.t("The refund of {{amount}} {{currency}} has been made.",
                    {
                        amount: this.state.targetAmount,
                        currency: process.env.REACT_APP_CURRENCY
                    }
                )
            }
        </p>
    )

    confirmRefundOrder = () => {
        const { isRefundConfirmation, targetAmount } = this.state;
        const { t } = this.props;

        if (isRefundConfirmation) {
            return (
                <div>
                    <p className="loan-display">
                        {t("Confirm refund")}{' - '}
                        <b>{targetAmount} {process.env.REACT_APP_CURRENCY}</b>?
                    </p>
                    <button
                        className="btn btn-sm btn-warning btnAction btnTransferSpec"
                        type="button"
                        value="cancel"
                        onClick={this.btnRefundOrder}>
                        <i className="fa fa-fw fa-retweet"></i> {t("Order refund")}
                    </button>
                    <button
                        className="btn btn-sm btn-default btnAction"
                        type="button"
                        value="cancel"
                        onClick={this.cancelRefund}>
                        <i className="fa fa-fw fa-retweet"></i> {t("Cancel refund")}
                    </button>
                </div>
            );
        }
    };

    render() {
        return (
            <>
                <Button
                    variant=""
                    onClick={this.handleShow}
                    className="btn btn-link btnEditOrder">
                    <i className="fa fa-bars" style={{ color: 'grey' }}></i>
                </Button>
                <Modal show={this.state.showModal} onHide={this.handleClose} onExit={this.props.handleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {this.state.showModal && this.handleTitle()}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <dl className="row">
                            {this.state.showModal && !this.state.isRefundConfirmation && !this.state.showCompleted && this.handleModalBody()}
                            {this.state.isRefundConfirmation && this.confirmRefundOrder()}
                            {this.state.showCompleted && this.refundCompleted()}
                        </dl>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { ...state };
};

export default connect(mapStateToProps, {
    getOnlineTransactionDetails,
    postP3GReturn,
})(withTranslation()(P3GModal));
// export default P3GModal;
