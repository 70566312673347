import React from "react";
import { FaQuestion } from "react-icons/fa";
import { IconButton as MIconButton } from "@mui/material";

import { colors } from "../../../styles/variables";

const IconButton = ({
    Icon = FaQuestion,
    size = 16,
    isActive,
    ...props
}) => (
    <MIconButton
        sx={{ color: !!isActive ? colors.paypoGreen500 : colors.paypoGrey200 }}
        { ...props }
    >
        <Icon size={ size } /> 
    </MIconButton>
);

export default IconButton;
