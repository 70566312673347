import React from "react";
import {colors} from "../../../styles/variables";
import {Checkbox as MuiCheckbox} from "@mui/material";

const Checkbox = ({size = 28, ...props}) => (
    <MuiCheckbox
        {...props}
        sx={{
            '& .MuiSvgIcon-root': {fontSize: size},
            '&.Mui-checked': {
                color: colors.paypoGreen500,
            },
        }}
    />
);

export default Checkbox;