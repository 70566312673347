import React from 'react';
import {AutoSizer, Column, Table} from "react-virtualized";
import TableCell from "@mui/material/TableCell";
import Text from "../../atoms/Typography/Text";

const VirtualizedTable = ({
    columns,
    rowHeight = 48,
    headerHeight = 48,
    ...tableProps
}) => {
    const headerRenderer = ({label, columnIndex}) => {
        return (
            <TableCell
                component="div"
                className={"virtualizedTable__flexContainer virtualizedTable__headerRow virtualizedTable__tableCell"}
                variant="head"
                style={{
                    height: headerHeight,
                }}
                align={columns[columnIndex].numeric || false ? 'right' : 'left'}
            >
                <Text bold>{label}</Text>
            </TableCell>
        );
    }

    const cellRenderer = ({cellData, columnIndex}) => {
        const row = columns[columnIndex];

        return (
            <TableCell
                component="div"
                variant="body"
                size={'small'}
                className={"virtualizedTable__flexContainer virtualizedTable__tableCell"}
                align={
                    (columnIndex != null && columns[columnIndex].numeric) || false
                        ? 'right'
                        : 'left'
                }
            >
                {!!row.CellRenderer ? (
                    <row.CellRenderer row={row} value={cellData}/>
                ) : (
                    <Text>{cellData}</Text>
                )}
            </TableCell>
        );
    }

    return (
        <AutoSizer>
            {({height, width}) => (
                <Table
                    height={height}
                    width={width}
                    rowHeight={rowHeight}
                    gridStyle={{
                        direction: 'inherit',
                    }}
                    headerHeight={headerHeight}
                    {...tableProps}
                    rowClassName={'virtualizedTable__flexContainer virtualizedTable__tableRowHover'}
                >
                    {columns.map(({dataKey, ...other}, index) => {
                        return (
                            <Column
                                key={dataKey}
                                headerRenderer={(headerProps) =>
                                    headerRenderer({
                                        ...headerProps,
                                        columnIndex: index,
                                    })
                                }
                                className={'virtualizedTable__flexContainer'}
                                cellRenderer={cellRenderer}
                                dataKey={dataKey}
                                {...other}
                            />
                        );
                    })}
                </Table>
            )}
        </AutoSizer>
    );
}

export default VirtualizedTable;