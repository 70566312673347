import styled from 'styled-components';
import { Box } from '@mui/material';

import { colors } from '../../../styles/variables';

const setModalSize = size => {
    switch (size) {
        case 's':
            return '48rem';
        case 'l':
            return '134.4rem';
        case 'm':
        default:
            return '72rem';
    };
};

export const ModalBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${ colors.white };
    box-shadow: ${ colors.paypoShadowGrey03 };
    padding: 2rem;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border-radius: .8rem;
    max-width: 95vw;
    max-height: 85vh;
    
    overflow-y: ${
        ({ scroll }) => !!scroll ? 'scroll': 'hidden'
    };
    width: ${ 
        ({ size }) => 
            setModalSize(size)
    };
`;
