import React from 'react';

import Spinner from "./Spinner";

import './style.css';

const LoaderView = () => (
  <main className='loader'>
    <Spinner></Spinner>
  </main>
);
export default LoaderView;
