import styled from "styled-components";

import { breakpoints } from "../../../styles/variables";

export const ButtonsFactory = styled.div`
    display: flex;
    flex-direction: ${ ({ disableBreakpoints }) => disableBreakpoints ? 'row' : 'column' };
    justify-content: ${ ({ justifyContent }) => justifyContent };
    align-items: center;

    ${ ({ reverseOnMobile }) => reverseOnMobile && 'flex-direction: column-reverse' };

    @media (min-width: ${ breakpoints.medium }) {
        flex-direction: row;
    }

    > * {
        width: ${ ({ disableBreakpoints }) => disableBreakpoints ? 'initial' : '100%' };
        
        ${ ({ disableBreakpoints }) => disableBreakpoints ? 'margin-right: 16px' : 'margin-bottom: 16px' };

        ${ ({ reverseOnMobile }) => !reverseOnMobile ? `
            &:last-child {
                margin-right: 0;
            }

            @media (min-width: ${ breakpoints.medium }) {
                width: initial;
                margin: 0 16px 0 0;

                &:last-child {
                    margin-right: 0;
                }
            }
        ` : `
            &:first-child {
                margin: 0;
            }

            @media (min-width: ${ breakpoints.medium }) {
                width: initial;
                margin: 0;

                &:first-child {
                    margin-right: 16px;
                }
            }
        `};
    }
`;
