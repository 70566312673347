import styled from "styled-components";
import {Select as MuiSelect} from "@mui/material";
import {colors} from "../../../styles/variables";


export const Select = styled(MuiSelect)`
      min-width: 160px;
  
      &.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: ${colors.paypoGreen600};
}`;