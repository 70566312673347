import { useEffect } from 'react';

export const useCookiebot = showCookiebot => {
    useEffect(() => {
        if (!showCookiebot) return;

        const externalScript = document.createElement('script');
        externalScript.src = 'https://consent.cookiebot.com/uc.js';
        externalScript.id = 'Cookiebot';
        externalScript.dataset.cbid = 'a378c2c1-9730-4e23-90d8-dc7e6fd6da89';
        externalScript.type = 'text/javascript';
        externalScript.async = true;
        document.body.append(externalScript);
  
        const googleConsentMode = document.createElement('script');
        const googleTagManager = document.createElement('script');
  
        googleConsentMode.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments)
            }
            gtag("consent", "default", {
                ad_storage: "denied",
                analytics_storage: "denied",
                functionality_storage: "denied",
                personalization_storage: "denied",
                security_storage: "granted",
                wait_for_update: 2000
            });
            gtag("set", "ads_data_redaction", true);
        `;
  
        googleTagManager.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WTLSGTW');  
        `;
  
        document.head.append(googleConsentMode);
        document.head.append(googleTagManager);
  
        return () => {
            externalScript.remove();
            googleConsentMode.remove();
            googleTagManager.remove();
        };
    }, []);
};
