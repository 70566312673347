import React from 'react';
import { SH4 } from './styles';

const H4 = ({
    children,
    className = '',
    titleUnderline,
    textAlign = 'center',
    colorGreen = false,
}) => (
    <SH4
        titleUnderline={ titleUnderline }
        textAlign={ textAlign }
        colorGreen={ colorGreen }
        className={ className }
    >
        { children }
    </SH4>
);


export default H4;
