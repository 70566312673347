import styled from "styled-components";
import {colors} from "../../../styles/variables";

export const DateRangePickerWrapper = styled.div`
    line-height: 1;
  
    & .DateInput_fang {
      display: none;
    }
  
    & .DateRangePickerInput__withBorder .DateInput_input__focused {
        border-bottom-color: ${colors.paypoGreen600};
    }
  
    & .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
        background: ${colors.paypoGreen600};
        border-color: ${colors.paypoGreen600};
    }
  
    & .CalendarDay__selected_span {
        background: ${colors.paypoGreen400};
        border-color: ${colors.paypoGreen400};
    }
  
    & .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
        background: ${colors.paypoGreen400};
        border-color: ${colors.paypoGreen400};
        color: ${colors.white};
    }
`;
