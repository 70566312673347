import React from 'react';
import { connect } from 'react-redux';
import { merchantShopList, regenerateToken } from '../actions';
import logo from './utilities/gfx/logo_PayPo_Kolor.png';
import { withTranslation } from 'react-i18next';

class ChooseMerchant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shopValue: null,
      showList: false,
      renderToNextPage: false,
    };
  }
  async componentDidMount() {
    localStorage.setItem('username', this.props.userData?.token.user_name);
    await this.props
        .merchantShopList(
            this.props.userData.token.user_email,
            localStorage.token
        )
        .then(() => {
          if (this.props.userData.shopList.length === 1) {
            window.location.pathname = '/salesmanpanel';
          } else {
            this.setState({
              shopValue: this.props.userData.shopList[0].ID,
              showList: true,
            });
          }
        });
  }

  renderSelectOptions = () => {
    if (this.props.userData.shopList) {
      return this.props.userData.shopList.map((shop) => {
        return (
            <option
                key={shop.ID}
                value={shop.ID}
                id={shop.ID}
                name={shop.CompanyName}
            >
              {shop.CompanyName}
            </option>
        );
      });
    }
  };

  handleSelectedShop = (e) => {
    this.setState({ shopValue: e.target.value });
  };

  chooseShop = async (e) => {
    let shopName = document
        .getElementById(this.state.shopValue)
        .getAttribute('name');
    localStorage.setItem('merchant', shopName);
    localStorage.setItem('merchantId', this.state.shopValue)
    await this.props
        .regenerateToken(localStorage.token, parseInt(this.state.shopValue))
        .then(() => {
          this.setState({ redirectToNextPage: true });
        });
  };

  redirectHelper = () => {
    if (this.state.redirectToNextPage) {
      return window.location.pathname = '/salesmanpanel';
    }
  };

  render() {
    const { t } = this.props;

    return (
        <>
          {' '}
          {this.state.showList && (
              <div className='container-fluid login-wrapper'>
                <div className='card login'>
                  <div className='card-body'>
                    <img className='card-img' src={logo} alt='paypologo' />

                    <h4 className='card-title'>{t("Choose merchant")}</h4>
                    <div className='form-group'>
                      <select
                          value={this.state.shopValue}
                          onChange={this.handleSelectedShop}
                          className='form-control'
                      >
                        {this.renderSelectOptions()}
                      </select>
                      <button
                          type='button'
                          className='btn btn-lg btn-primary btn-block btn-pplogin'
                          onClick={this.chooseShop}
                      >
                        {t("Confirm")}
                      </button>
                      {this.redirectHelper()}
                    </div>
                  </div>
                </div>
              </div>
          )}
        </>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state };
};

export default connect(mapStateToProps, {
  merchantShopList,
  regenerateToken
})(withTranslation()(ChooseMerchant));
