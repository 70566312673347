import React, {useState} from 'react';
import { withTranslation } from 'react-i18next';

const UserRow = ({user, save, t, canEdit = false}) => {
    const [userRole, setUserRole] = useState(user.role.id);
    const [userActive, setUserActive] = useState(user.status);
    const [editMode, setEditMode] = useState(false);

    const handleRoleChange = (e) => {
        setUserRole(e.target.value)
    }

    const handleStatusChange = (e) => {
        setUserActive(e.target.value)
    }

    const handleSaveClicked = () => {
        let data = {
            userId: +user.id,
            active: +userActive,
            role: +userRole,
        };
        save(data, handleCloseEditMode);
    }

    const handleOpenEditMode = () => {
        canEdit && setEditMode(true);
    }

    const handleCloseEditMode = () => {
        setEditMode(false);
    }

    return editMode ? <tr key={user.id}>
        <td name='email'>{user.email}</td>
        <td name='login'>{user.login}</td>
        <td name='userName'>{user.name}</td>
        <td name='phone'>{user.phone}</td>
        <td>
            <select
                name='userRole'
                className='form-control'
                value={userRole}
                onChange={handleRoleChange}
            >
                <option value='1'>{t("Administrator")}</option>
                <option value='2'>{t("Programmer")}</option>
                <option value='3'>{t("User")}</option>
                <option value='4'>{t("Guest")}</option>
            </select>
        </td>
        <td>
            <select
                name='userActive'
                className='form-control'
                value={userActive}
                onChange={handleStatusChange}
            >
                <option value='1'>{t("Active")}</option>
                <option value='0'>{t("Inactive")}</option>
            </select>
        </td>
        <td name='added'>{user.createdAt}</td>
        <td>
            <button
                type='submit'
                className='btn btn-primary btn-sm'
                aria-label='Zapisz'
                onClick={handleSaveClicked}
            >
                {t("Save")} <i className='fa fa-check'></i>
            </button>
        </td>
    </tr> :
        <tr key={user.id}>
            <td name='email'>{user.email}</td>
            <td name='login'>{user.login}</td>
            <td name='userName'>{user.name}</td>
            <td name='phone'>{user.phone}</td>
            <td name='role'>{user.role.label}</td>
            <td name='status'>
                {user.status === true ? t("Active") : t("Inactive")}
            </td>
            <td name='added'>{user.createdAt}</td>
            {canEdit && <td>
                <button
                    type='button'
                    className='btn btn-primary btn-sm'
                    aria-label='Edytuj'
                    onClick={handleOpenEditMode}
                >
                    <i className='fa fa-edit' value={user.id}></i>
                </button>
            </td>}
        </tr>
}

export default withTranslation()(UserRow);