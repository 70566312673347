import React from "react";
import {Stack} from "@mui/material";

const Wrapper = ({
    children,
    fullWidth,
    directionRow,
    flexWrap,
    ssx,
    ...props
}) => (
    <Stack
        sx={{
            width: !!fullWidth ? '100%' : 'initial',
            ...ssx,
        }}
        direction={!!directionRow ? 'row' : 'column'}
        flexWrap={!!flexWrap ? 'wrap': 'nowrap'}
        {...props}
    >
        {children}
    </Stack>
);

export default Wrapper;
