import React, {useState} from 'react';
import i18n from "i18next";
import {LANGUAGES} from "../constants/language";

const LanguageSwitcher = () => {
    const [language, setLanguage] = useState(i18n.language);
    const [languages, setLanguages] = useState(i18n.store.options.supportedLngs);

    const changeLanguage = (e) => {
        let lang = e.target.value;

        setLanguage(lang);
        i18n.changeLanguage(lang).then();
        localStorage.setItem('lang', lang);
    }

    const renderLangOptions = () => {
        return languages.filter(el => {
            if(el === 'cimode') {
                return false;
            }
            return true;
        }).map(value => {
            const label = LANGUAGES.find(el => el.id === value)?.label || value

            return <option key={value} value={value}>{label}</option>
        })
    }

    return (
        <select
            name='selectLanguage'
            className='form-control switch-language'
            value={language}
            onChange={changeLanguage}
        >
            {renderLangOptions()}
        </select>
    )
}

export default LanguageSwitcher;
