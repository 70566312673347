import React from "react";
import {useTranslation} from "react-i18next";
import {Controller, useFormContext} from "react-hook-form";
import {FormControlLabel} from "@mui/material";
import Wrapper from "../../atoms/Wrapper/Wrapper";
import DatePicker from "../../atoms/DatePicker";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import Text from "../../atoms/Typography/Text";
import Button from "../../atoms/Button";

const CorrectionFilters = ({onSubmit, handleReset, isFiltered}) => {
    const {control, handleSubmit, formState:{errors}} = useFormContext();
    const {t} = useTranslation();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Wrapper directionRow flexWrap sx={{gap: "10px"}}>
                <Controller
                    name="dateFrom"
                    control={control}
                    render={({field: {onChange, value}}) =>
                        <DatePicker
                            onChange={onChange}
                            value={value}
                            error={!!errors.dateFrom}
                            helperText={errors.dateFrom?.message}
                            sx={{minWidth: 220}}
                        />
                    }
                />
                <Controller
                    name="dateTo"
                    control={control}
                    render={({field: {onChange, value}}) =>
                        <DatePicker
                            onChange={onChange}
                            value={value}
                            error={!!errors.dateTo}
                            helperText={errors.dateTo?.message}
                            sx={{minWidth: 220}}
                        />
                    }
                />
                <Controller
                    name="onlyFailedCorrections"
                    control={control}
                    render={({field: {onChange, value}}) =>
                        <FormControlLabel
                            control={<Checkbox checked={value}/>}
                            onChange={onChange}
                            label={
                                <Text>
                                    {t('Show incorrect corrections')}
                                </Text>
                            }
                        />
                    }
                />
                <Button
                    onClick={handleReset}
                    size={'s'}
                    disabled={!isFiltered}
                >
                    {t("Reset")}
                </Button>
                <Button
                    type={"submit"}
                    size={'s'}
                >
                    {t("Filter")}
                </Button>
            </Wrapper>
        </form>
    );
}

export default CorrectionFilters;