import merchantApi from "../../../apis/merchantApi";

export const getCorrectionFiles = async (data, signal) => {
    return await merchantApi.post(
        '/getCorrections',
        data,
        {
            signal: signal,
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        }
    )
}

export const getCorrectionFileDetails = async (data) => {
    return await merchantApi.post(
        '/getCorrection',
        data,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        }
    )
}

export const uploadCorrectionFile = async (data) => {
    return await merchantApi.post(
        '/uploadCorrections',
        data,
        {
            headers: {
                'Authorization': `Bearer ${localStorage.token}`,
            },
        }
    );
};