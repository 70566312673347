import styled from "styled-components";
import { colors, breakpoints } from "../../../styles/variables";

const setButtonPadding = size => {
    switch (size) {
        case 's':
            return 'padding: .88rem 2.8rem;';
        case 'm/s':
            return `
                padding: 1.2rem 4rem;

                @media (min-width: ${ breakpoints.big }) {
                    padding: .6rem 2.8rem;
                }
            `;
        case 's/m':
            return `
                padding: .6rem 2.8rem;
            
                @media (min-width: ${ breakpoints.big }) {
                    padding: 1.2rem 4rem;
                }
            `;
        case 'm':
        default:
            return 'padding: 1.2rem 4rem;';
    }
};

const setFontSize = size => {
    switch (size) {
        case 's':
            return 'font-size: 1.4rem';
        case 'm/s':
            return `
                font-size: 1.6rem;

                @media (min-width: ${ breakpoints.big }) {
                    font-size: 1.4rem;
                }
            `;
        case 's/m':
            return `
                font-size: 1.4rem;
                
                @media (min-width: ${ breakpoints.big }) {
                    font-size: 1.6rem;
                }
            `;
        case 'm':
        default:
            return 'font-size: 1.6rem';
    }
};

const insertButtonStyles = (disabled, size) => (`
    background-color: ${ disabled ? colors.mintCream : colors.paypoGreen500 };
    color: ${ disabled? colors.paypoGrey200 : colors.blackHighContrast };
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
    line-height: 2.4rem;
    text-align: center;
    width: 100%;
    max-width: 100%;
    cursor: ${ disabled ? 'default' : 'pointer'};
    border: none;
    border-radius: 2.6rem;
    transition: .2s;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    height: fit-content;

    ${ setButtonPadding(size) };
    ${ setFontSize(size) };

    path {
        transition: .2s;
    }

    &.textButton {
        padding: 0;
        background: none;
        color: ${ colors.paypoGreen700 };
    }

    &.black {
        color: ${ colors.white };
        background-color: ${ disabled ? colors.paypoInactive : colors.black };
        border: .2rem solid ${ colors.black };

        &:hover {
            color: ${ colors.black };
            background-color: ${ disabled ? colors.paypoInactive : colors.white };
        }

        &:active {
            background-color: ${ disabled ? colors.paypoInactive : colors.black };
            color: ${ colors.white };

            &.textButton {
                background: none;
            }
        }
    }

    &.white {
        color: ${ disabled ? colors.paypoInactive : colors.blackHighContrast };
        background-color: ${ colors.white };
        box-shadow: ${ colors.paypoShadowGrey01 };

        &:hover {
            color: ${ disabled ? colors.paypoInactive : colors.white };
            background-color: ${ disabled ? colors.white : colors.black };
        }
    }

    &:hover {
        background-color: ${ disabled ? colors.mintCream : colors.paypoGreen300 };
        color: ${ disabled ? colors.paypoGrey200 : colors.paypoGreen700 };

        &.textButton {
            background: none;
        }

        path {
            stroke: ${ colors.white };
        }
    }

    &:active {
        background-color: ${ disabled ? colors.mintCream : colors.paypoGreen600 };
        
        &.textButton {
            background: none;
        }
    }
    
    &:focus {
        outline: 0;
    }

    @media (min-width: ${ breakpoints.small }) {
        width: initial;
    }
`);

export const SButton = styled.button`
    ${ ({ disabled, size }) => insertButtonStyles(disabled, size) }
`;

export const SLink = styled.a`
    ${ ({ disabled, size }) => insertButtonStyles(disabled, size) }
`;

export const SButtonIcon = styled.span`
    display: flex;
    align-items: center;
    max-height: 100%;
`;

export const SButtonIconLeft = styled(SButtonIcon)`
    margin-right: 1.2rem;
`;

export const SButtonIconRight = styled(SButtonIcon)`
    margin-left: 1.2rem;
`;
