export default (state = {}, action) => {
    switch (action.type) {
        case 'GET_TRANSACTIONS':
            return { ...state, transactions: action.payload };
        case 'GET_TRANSACTIONS_PAGINATION':
            return { ...state, pagination: action.payload };
        case 'GET_EXPORT_P3G_TRANSACTIONS':
                return { ...state, exportUrl: action.payload };
        case 'GET_TRANSACTIONS_FILTERS':
            return { ...state, filters: action.payload };
        case 'GET_MERCHANT_GROUP':
            return { ...state, merchantGroup: action.payload };
        default:
            return state;
    }
};
