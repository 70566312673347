import React from "react";
import {useQuery} from "react-query";
import {getP3GTransactionDetails, getP3GTransactions} from "../services/transactionService";
import {toast} from "react-toastify";
import {t} from "i18next";
import getParsedZoneDate from "../../parserDate";

export const useP3GTransactions = (data, page, rowsPerPage) => {
    const {canonicalId, orderReference, providerCanonicalId, date} = data;

    return useQuery({
        queryKey: ["p3gTransactions", data, page],
        queryFn: async ({signal}) => {
            return await getP3GTransactions(
                {
                    canonicalId: !!canonicalId ? canonicalId : null,
                    orderReference: !!orderReference ? orderReference : null,
                    providerCanonicalId: !!providerCanonicalId ? providerCanonicalId : null,
                    dateFrom: !!date.startDate ? getParsedZoneDate(date.startDate, 'YYYY-MM-DDT00:00:00') : null,
                    dateTo: !!date.endDate ? getParsedZoneDate(date.endDate, 'YYYY-MM-DDT23:59:59') : null,
                    page,
                    rowsPerPage,
                    status: null,
                }, signal);
        },
        onError: () => {
            toast.error(t("List loading error"));
        },
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 0,
    });
}

export const useP3GTransactionDetails = (data) => {
    return useQuery({
        queryKey: ["p3gTransactionDetails", data.ledgerId],
        queryFn: async ({signal}) => {
            return await getP3GTransactionDetails(data, signal);
        },
        onError: () => {
            toast.error(t("List loading error"));
        },
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 0,
    });
}