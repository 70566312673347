import React from "react";
import {useQuery} from "react-query";
import {getCorrectionFileDetails, getCorrectionFiles} from "../services/correctionService";
import {toast} from "react-toastify";
import {t} from "i18next";
import {getMerchantId, getUserId} from "../../getDataFromToken";
import {ROWS_PER_PAGE} from "../../../constants/apiSettings";

export const useCorrectionFiles = (data, page) => (
    useQuery({
        queryKey: ["correctionFiles", data, page],
        queryFn: async ({signal}) => {
            return await getCorrectionFiles(
                {
                    id: getUserId(),
                    merchantId: getMerchantId(),
                    rowsPerPage: ROWS_PER_PAGE,
                    page,
                    ...data
                },
                signal
            )
        },
        onError: () => {
            toast.error(t("List loading error"));
        },
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 0,
    })
)

export const useCorrectionFileDetails = (correctionFileId) => (
    useQuery({
        queryKey: ["correctionFileDetails", getMerchantId(), correctionFileId],
        queryFn: async ({signal}) => {
            return await getCorrectionFileDetails({
                    id: correctionFileId,
                    merchantId: getMerchantId(),
                },
                signal
            )
        },
        onError: () => {
            toast.error(t("List loading error"));
        },
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 0,
    })
)