import ro from './../locales/ro.json';
import pl from './../locales/pl.json';

export const LANGUAGES = [
    {
        id: 'ro',
        label: 'Romana',
        translation: ro
    },
    {
        id: 'pl',
        label: 'Polski',
        translation: pl
    },
]

export const getResources = () => {
    const result = {};

    LANGUAGES.forEach(el =>
        result[el.id] = {translation: el.translation}
    );

    return result;
}
