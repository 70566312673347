import styled from "styled-components";
import {colors} from "../../../../styles/variables";

export const Text = styled.p`
    font-family: "Poppins", sans-serif;
    line-height: 2.4rem;
    margin-bottom: 0;
    width: ${
        ({ fullWidth }) => fullWidth
            ? '100%'
            : 'initial'
    };
    
    text-align: ${
        ({ align }) => !!align
            ? align
            : 'left'
    };
    
    color: ${
        ({ type }) => !!type
            ? colors[type]
            : colors.blackHighContrast
    };
        
    font-weight: ${
        ({ bold }) => !!bold
            ? '700'
            : '500'
    };
    
    font-size: ${
        ({ fontSize }) =>  !!fontSize
            ? fontSize
            : '1.4rem'
    };
`;