export default (state = {}, action) => {
    switch(action.type){
        case 'GET_TOKEN':
            return {...state, token: action.payload}
        case 'REGENERATE_TOKEN':
            return {...state, token: action.payload}
        case 'GET_SHOP_LIST':
            return {...state, shopList: action.payload}
        case 'GET_USER_TOKEN':
            return {...state, token: action.payload}
        case 'INVALID_USER':
            return {...state, token: action.payload}
        default:
            return state
    }
}