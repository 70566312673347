export const CORRECTION_FILE_STATUSES = [
    {
        id: 0,
        value: 0,
        label: "Saved",
    },
    {
        id: 1,
        value: 1,
        label: "Corrections imported",
    },
    {
        id: 2,
        value: 2,
        label: "Processed",
    },
    {
        id: 3,
        value: 3,
        label: "In progress",
    },
    {
        id: 4,
        value: 4,
        label: "Processed with errors",
    },
    {
        id: 5,
        value: 5,
        label: "Processed without corrections",
    }
];