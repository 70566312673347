import React, {useState} from "react";
import * as yup from "yup";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Modal from "../../../molecules/Modal";
import Wrapper from "../../../atoms/Wrapper/Wrapper";
import Text from "../../../atoms/Typography/Text";
import MoneyWithCurrency from "../../../molecules/MoneyWithCurrency/MoneyWithCurrency";
import {useP3GTransactionDetails} from "../../../../services/api/queries/useTransactions";
import H6 from "../../../atoms/Typography/H6";
import P3GTransactionRefundForm from "./P3GTransactionRefundForm";
import Spinner from "../../../loader/Spinner";
import {useTransactionRefund} from "../../../../services/api/mutations/useTransactions";
import {Divider, Typography} from "@mui/material";
import {toast} from "react-toastify";
import P3GTransactionStatus from "../../../molecules/P3GTransactionStatus/P3GTransactionStatus";
import {LEDGER_STATUSES} from "../../../../constants/ledgerStatus";
import Money from "../../../atoms/Money";

const P3GTransactionDetailsModal = ({open, onClose, refetch, transactionData}) => {
    const {t} = useTranslation();
    const [isConfirmation, setConfirmation] = useState(false);
    const {data, isLoading} = useP3GTransactionDetails({ledgerId: transactionData.id});

    const handleLedgerStatus = (status) => {
        return LEDGER_STATUSES.find((ledgerStatus) => status === ledgerStatus.value).label;
    }

    const initialValues = {
        amount: 0,
    };

    const validationSchema = yup.object().shape({
        amount: yup.number().typeError(t("Amount must be a number"))
            .min(0.01, `${t("Minimum amount is")} 0.01${process.env.REACT_APP_CURRENCY}`)
            .max(transactionData.order.amount/100, `${t("Maximum amount is")} ${transactionData.order.amount/100} ${process.env.REACT_APP_CURRENCY}`),
    });

    const methods = useForm({
        resolver:yupResolver(validationSchema),
        defaultValues: initialValues,
    });

    const onSuccess = () => {
        toast.success("The refund has been made.")
        onClose();
        refetch();
    }

    const transactionRefundMutation = useTransactionRefund(onSuccess);
    const onSubmit = (data) => {
        transactionRefundMutation.mutate({
            amount: data.amount * 100,
            current: transactionData.order.amount,
            transactionId: transactionData.id,
        })
    }

    return (
        <Modal
            title={t("Transaction {{fullID}}", {fullID: transactionData?.currentPayment?.canonicalId})}
            isVisible={open}
            onClose={onClose}
            size={"s"}
        >
            <Wrapper ssx={{paddingBottom: "1rem"}}>
                <Text><b>{t("Base/Initial amount")}: </b><MoneyWithCurrency value={transactionData.order.baseAmount} /></Text>
                <Text><b>{t("Current amount")}: </b><MoneyWithCurrency value={transactionData.order.amount} /></Text>
                <Text><b>{t("Status")}: </b><P3GTransactionStatus status={transactionData.status} baseAmount={transactionData.order.baseAmount} currentAmount={transactionData.order.amount}/></Text>
                <Text><b>{t("Order number")}: </b>{transactionData.order.reference}</Text>
            </Wrapper>
            <Divider />
            {!!data?.data?.length &&  <Wrapper ssx={{padding: "1rem 0"}}>
                {!!isLoading && <Wrapper ssx={{minHeight: "50px", position: "relative"}}><Spinner/></Wrapper>}
                {!isLoading && <Wrapper>
                    {
                        data?.data?.map((ledger) => {
                            return (<Text key={ledger.LedgerId + ledger.Created + ledger.Amount}>
                                {`${ledger.Created} ${t(handleLedgerStatus(+ledger.Operation))} `}
                                <Money value={ledger.Amount}/>
                            </Text>)
                        })
                    }
                </Wrapper>}
            </Wrapper>}
            {!!data?.data?.length && <Divider />}
            <Wrapper ssx={{paddingTop: "1rem"}}>
                {!isConfirmation && <H6 textAlign={"left"}>
                    {t("Enter amount of refund")}
                </H6>}
                {!!isConfirmation && <Typography fontSize={"1.4rem"} textAlign={"left"}>
                    {`${t("Confirm refund")} - `}
                    <b>{`${methods.getValues("amount")} ${process.env.REACT_APP_CURRENCY}`}</b>
                    ?
                </Typography>}
                <FormProvider {...methods}>
                    <P3GTransactionRefundForm
                        onSubmit={onSubmit}
                        isConfirmation={isConfirmation}
                        setConfirmation={setConfirmation}
                    />
                </FormProvider>
            </Wrapper>
        </Modal>
    );
}

export default P3GTransactionDetailsModal;
