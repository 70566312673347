import React from 'react';
import { SH6 } from './styles';

const H6 = ({
    children,
    className = '',
    titleUnderline,
    textAlign = 'center',
    colorGreen = false,
}) => (
    <SH6
        titleUnderline={ titleUnderline }
        textAlign={ textAlign }
        colorGreen={ colorGreen }
        className={ className }
    >
        { children }
    </SH6>
);

export default H6;
