import React from "react";
import {SButton} from "../../atoms/Button/styles";
import {CircularProgress} from "@mui/material";

const LoadingButton = ({
    label,
    isLoading,
    ...props
}) => {
    return (
        <SButton
            disabled={isLoading}
            {...props}
        >
            {!isLoading && label}
            {isLoading && <CircularProgress size={'2rem'} />}
        </SButton>
    );
}

export default LoadingButton;